import { environment } from 'src/environments/environment';
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

/**
 * Der Service hinterlegt und liefert die Meldungen zurück
 */


@Injectable()
export class MembernewsSenderService {


  private apiurl = environment.apiRootDirectory;


  constructor(private _http: HttpClient) { }

    /**
   *  lies die meldungen zurück
   */
     readMembernews(principalDomain:string) {

      return this._http.post<any>(this.apiurl + "data/oewamembernews/readMembernewsByMember.php", {
        "principalDomain": principalDomain
  
      });
  
    }

    /**
   *  blendet die meldung aus
   */
     hideMembernews(membernewsid:string, hiddenBy:string) {

      return this._http.post<any>(this.apiurl + "data/oewamembernews/hideMembernews.php", {
        "id": membernewsid,
        "hiddenBy": hiddenBy
  
      });
  
    }

  /**
   *  lies die meldungen zurück
   */
  saveMembernews(headline:string, news:string, pubtill:string, whichmember:string, pubFrom:string, userid:string, publish:string, history:string, language:string,  deleted:string ) {

    return this._http.post<any>(this.apiurl + "data/oewamembernews/create.php", {
      "news": news,
      "headline": headline,
      "whichmember": whichmember,
      "pub_till":pubtill,
      "languages_fk":language,
      "deleted":deleted,
      "history":history,      
      "pub_from":pubFrom,
      "publish":publish,
      "userid":userid,

    });

  }

}