import { AuthService } from "src/app/services/auth.service";
import { TokenService } from "src/app/services/token.service";
import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

/**
 * Komponent-Klasse zum Auslogen
 */

@Component({
  selector: "signout",
  templateUrl: "./signout.component.html",
  styleUrls: ["./signout.component.css"]
})
export class SignoutComponent implements OnInit {

  // Auth-Status
  authStatus: boolean;

  /**
   * Konstruktor
   * @param authService, Service für Auth
   * @param tokenService, Service für das JWT 
   * @param router, Navigationshändler 
   */
  constructor(private authService: AuthService, private tokenService: TokenService, private router: Router) { }

  ngOnInit() {
    this.authStatus = this.tokenService.jwtokenExists() && this.tokenService.isJwtTokenValid();
  }

  /**
   * Navigation zum Header (Würzel der Navigation)
   */
  onContinue() {
    this.router.navigate(["/"]);
  }

  /**
   * handelt das Logout
   */
  onSignOut() {
    // User properly auslogen
    this.authService.signOutUser();
    this.router.navigate(["/"]);
  }
}
