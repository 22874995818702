import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { GenericDisplaySservice } from '../services/genericDisplay.service';
import outputmessage from "src/assets/messagetokens.json";
import { AuthService } from '../services/auth.service';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';

/**
 * Die Klasse handelt die Errors die aus dem Backend kommen
 */

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

    /**
     * 
     * @param authenticationService, Service für Auth 
     * @param genericDisplaySservice, Service für die Warnungsanzeige 
     */
    constructor(private authenticationService: AuthService, private genericDisplaySservice: GenericDisplaySservice) { }

    /**
     * fängt jedes Backendseitige Error im Frontend
     * @param request, das Request 
     * @param next, der nächste HttpHändler 
     */
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        return next.handle(request).pipe(catchError(err => {         

            // UNAUTHORIZED
            if (err.status == 401 || Number(err.status) === 401) {
                // auto logout if 401 response returned from PHP api
                this.authenticationService.signOutUser();
            }

            //console.log(err);

            // Anzeige des Modals mit der Fehlermeldung
            this.genericDisplaySservice.showAlert(outputmessage.headererrormodal, err);

            const error = (err && err.error && err.error.message) ||  (err && err.statusText);
            return throwError(error);
            
        }));
    
}
}
