import { ChangedFieldObject } from "./ChangedFieldObject.model";

/**
 * Modelklasse zur Gruppierung der Datenänderungen pro Mitglied
 */

export class ChangesFieldGroupedByPrincipal {

    constructor(
        public principalDomain: string,
        public customerdataChangesfields: ChangedFieldObject[],
        public offerdataChangesfields: ChangedFieldObject[],
        public localdataChangesfields: ChangedFieldObject[]
    ) {

    }
}

/**
 * Modelklasse zur Gruppierung der Datenänderungen nach dem Datum
 */
export class ChangesFieldGroupedByDate {

    /**
     * Konstruktor
     * @param Changedate, Bezugsdatum 
     * @param Changesfields, gruppierte Datenänderungen 
     */
    constructor(
        public Changedate: string,
        public Changesfields: ChangedFieldObject[],
    ) {

    }
}

/**
 * Modelklasse zur Gruppierung der Datenänderungen pro Angebot
 */
export class offerGroup {

    /**
     * Konstruktor
     * @param offername, Angebotskennung
     * @param websitename, Angebotsname 
     * @param groupedDataChangesfields, gruppierte Datenänderungen  
     */
    constructor(
        public offername: string,
        public websitename: string,
        public groupedDataChangesfields: ChangedFieldObject[],
    ) {

    }
}

/**
 * Modelklasse zur Gruppierung der Datenänderungen pro Mitglied und Offer
 */
export class ChangesFieldGroupedByPrincipalAndOffer {

    /**
     * 
     * @param principalDomain, Mitgliedskürzel
     * @param customerdataChangesfields, gruppierte Datenänderungen der allgemeinen Informationen 
     * @param offerdataChangesfields, gruppierte Datenänderungen  der Angebotsdaten
     * @param localdataChangesfields, gruppierte Datenänderungen  der Local- oder Apps-Daten
     * @param localOrAppChangeDescr, Bezeichnung Localänderungen | Apps-Änderungen
     */
    constructor(
        public principalDomain: string,
        public customerdataChangesfields: ChangedFieldObject[],
        public offerdataChangesfields: offerGroup[],
        public localdataChangesfields: offerGroup[],
        public localOrAppChangeDescr: string) {

    }
}