import { AccountrecoveryComponent } from './GUI-components/accountrecovery/accountrecovery.component';
import { PasswordchangeComponent } from './GUI-components/passwordchange/passwordchange.component';
import { PagenotfoundComponent } from './GUI-components/pagenotfound/pagenotfound.component';
import { PdfcontractComponent } from './GUI-components/pdfcontract/pdfcontract.component';
import { SignoutComponent } from './GUI-components/signout/signout.component';
import { SigninComponent } from './GUI-components/signin/signin.component';
import { AuthGuard } from './services/authguard.service';
import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';



const routes: Routes = [
  
  { path: 'signin', component: SigninComponent },

  { path: 'accountrecovery', component: AccountrecoveryComponent },

  { path: 'passwordchange', canActivate: [AuthGuard], component: PasswordchangeComponent },

  {
    path: 'agreementpreview',
    loadChildren: () => import('./lazyloadingmodules/additionalagreement/additionalagreement.module').then(modul => modul.AdditionalagreementModule)
  },

  {
    path: 'datachangessortedbydate',
    loadChildren: () => import('./lazyloadingmodules/datachangessortedbydate/datachangessortedbydate.module').then(modul => modul.DatachangessortedbydateModule)
  },
  

  {
    path: 'agreementdownloading',
    canActivate: [AuthGuard], component: PdfcontractComponent
  },
  {
    path: 'registrationchangescontractdownload',
    loadChildren: () => import('./lazyloadingmodules/registrationchangescontractdownloading/registrationchangescontractdownloading.module').then(modul => modul.RegistrationchangescontractdownloadingModule)
  },


  {
    path: 'registrationchangescontract',
    loadChildren: () => import('./lazyloadingmodules/registrationchangescontract/registrationchangescontract.module').then(modul => modul.RegistrationchangescontractModule)
  },
  {
    path: 'customerdata',
    loadChildren: () => import('./lazyloadingmodules/customerregistration/customerregistration.module').then(modul => modul.CustomerregistrationModule)
  },

  {
    path: 'offerlist/:principalDomain',
    loadChildren: () => import('./lazyloadingmodules/offerlist/offerlist.module').then(modul => modul.OfferlistModule)
  },

  {
    path: 'offeredit/:principaldomain/:domain',
    loadChildren: () => import('./lazyloadingmodules/offeredit/offeredit.module').then(modul => modul.OffereditModule)
  },

  {
    path: 'editmobiloffer/:principaldomain/:domain',
    loadChildren: () => import('./lazyloadingmodules/editmobileoffer/editmobileoffer.module').then(modul => modul.EditmobileofferModule)
  },

  {
    path: 'admininputdata',
    loadChildren: () => import('./lazyloadingmodules/admininputdata/admininputdata.module').then(modul => modul.AdmininputdataModule)
  },
  
  

  {
    path: 'datachangeprocessing',
    loadChildren: () => import('./lazyloadingmodules/adminpage/adminpage.module').then(modul => modul.AdminpageModule)
  },
  {
    path: 'help',
    loadChildren: () => import('./lazyloadingmodules/help/help.module').then(modul => modul.HelpModule)
  },
  {
    path: 'membernews',
    loadChildren: () => import('./lazyloadingmodules/membernews/membernews.module').then(modul => modul.MembernewsModule)
  },

  {
    path: 'webcatalogedit/:siteinfoId',
    loadChildren: () => import('./lazyloadingmodules/webcatalogedit/webcatalogedit.module').then(modul => modul.WebcatalogeditModule)
  },

    

  {
    path: 'advertisingmarket/:siteinfoId',
    loadChildren: () => import('./lazyloadingmodules/advertisingmarket/advertisingmarket.module').then(modul => modul.AdvertisingmarketModule)
  },
  
  {
    path: 'webcatalog',
    loadChildren: () => import('./lazyloadingmodules/webcatalog/webcatalog.module').then(modul => modul.WebcatalogModule)
  },
  {
    path: 'memberprofil',
    loadChildren: () => import('./lazyloadingmodules/memberprofil/memberprofil.module').then(modul => modul.MemberprofilModule)
  },
  
  {
    path: 'ordercenter',
    loadChildren: () => import('./lazyloadingmodules/ordercenter/ordercenter.module').then(modul => modul.OrdercenterModule)
  },
  { path: 'signout', canActivate: [AuthGuard], component: SignoutComponent },

  { path: '', redirectTo: '', pathMatch: 'full' },
  { path: 'not-found', component: PagenotfoundComponent },
  { path: '**', redirectTo: '/not-found' }

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload', relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
