import { ChangedFieldObject } from "../models/ChangedFieldObject.model";
import { StringFunctionsService } from "./stringFunctions.service";
import { contactTypes } from "../enumerations/contactTypes.enum";
import { changesTypes } from '../enumerations/changeTypes.enum';
import { TokenService } from './token.service';
import { Injectable } from "@angular/core";

/**
 * Der Service liefert die SQL-Statement zurück, um Datenänderungen in diverse Tabellen zu speichern
 */

@Injectable({
    providedIn: "root"
})
export class SqlQueryService {
    constructor(private stringservice: StringFunctionsService, private tokenService: TokenService) { }


    /**
     * * liefert das SQL Update Statement, um Datenänderungen der Localdaten zu speichern
     * @param item Änderungsobjekt
     */
    getInsertSqlQueryOfLocalistData(item: ChangedFieldObject) {

        let insertlocal: string = "INSERT INTO locallists (principalDomain, offername, local, lastUpdate)  VALUES  ('{0}','{1}','{2}', NOW())";

        if (item.tablename !== null && item.tablename !== undefined && item.tablename === "locallists") {
            return this.stringservice.FormatString(insertlocal, [item.principalDomain, item.offername, item.newvalue]);
        }


    }

    /**
     * * liefert das SQL Delete Statement, um Datenänderungen der Localdaten zu speichern
     * @param item Änderungsobjekt
     */
    getDeleteSqlQueryOfLocalistData(item: ChangedFieldObject) {

        let deletelocal: string = "DELETE FROM locallists WHERE offername='{0}' AND local='{1}' AND principalDomain='{2}'";

        if (item.tablename !== null && item.tablename !== undefined && item.tablename === "locallists") {
            return this.stringservice.FormatString(deletelocal, [item.offername, item.oldvalue, item.principalDomain]);
        }


    }

    /**
     * liefert das SQL INSERT Statement, um Datenänderungen der Firmendaten zu speichern
     * @param item Änderungsobjekt
     */
    getInsertSqlQueryOfCustomerData(item: ChangedFieldObject) {

        let insertCompany: string = "INSERT INTO company (principalDomain, {0}, lastUpdate)  VALUES  ('{1}','{2}', NOW())";
        let insertContact: string = "INSERT INTO contacts(principalDomain, contacttypeId, {0}, lastUpdate)  VALUES  ('{1}',{2},'{3}', NOW())";
        // dummy werte entfernen
        item = this.cleanDummyvalues(item);
        if (item.tablename !== null && item.tablename !== undefined && item.tablename === "company") {
            return this.stringservice.FormatString(insertCompany, [item.fieldname, item.principalDomain, item.newvalue]);
        }
        else if (item.tablename !== null && item.tablename !== undefined && item.fieldidentity !== null && item.fieldidentity !== undefined && item.tablename === "contacts") {
            return this.stringservice.FormatString(insertContact, [item.fieldname, item.principalDomain, item.fieldidentValue, item.newvalue]);
        }

    }
    /**
     * liefert das SQL Update Statement, um Datenänderungen der Firmendaten zu speichern
     * @param item Änderungsobjekt
     */
    getUpdateSqlQueryOfCustomerData(item: ChangedFieldObject) {

        let updateCompany: string = "UPDATE {0} SET {1} = '{2}', lastUpdate=NOW() WHERE principalDomain = '{3}'";
        let updateContact: string = updateCompany + " AND {4} = {5}";

        // dummy werte entfernen
        item = this.cleanDummyvalues(item);
        if (item.tablename !== null && item.tablename !== undefined) {
            if (item.tablename === "company") {
                return this.stringservice.FormatString(updateCompany, [item.tablename, item.fieldname, item.newvalue, item.principalDomain]);
            } else if (item.fieldidentity !== null && item.fieldidentity !== undefined && item.tablename === "contacts") {
                return this.stringservice.FormatString(updateContact, [item.tablename, item.fieldname, item.newvalue, item.principalDomain, item.fieldidentity, item.fieldidentValue]);

            }

        }
    }

    /**
     * entfernt die indikative Änderungstype damit sie NICHT in der DB gespeichert werden dürfen
     * @param item Änderungsobjekt
     */
    private cleanDummyvalues(item: ChangedFieldObject): ChangedFieldObject {
        if (item) {
            if (item.oldvalue)
                if (item.oldvalue === changesTypes.deleted ||
                    item.oldvalue === changesTypes.inserted ||
                    item.oldvalue === changesTypes.modified)
                    item.oldvalue = "";


            if (item.newvalue)
                if (item.newvalue === changesTypes.deleted ||
                    item.newvalue === changesTypes.inserted ||
                    item.newvalue === changesTypes.modified)
                    item.newvalue = "";
        }
        return item;
    }

    /**
     * Query um Angebot und seine Kontakten hinzufügen
     * @param item Änderungsobjekt
     */
    getInsertSqlQueryOfOfferData(item: ChangedFieldObject) {

        let insertOffer: string = "INSERT INTO offers (principalDomain, offername, {0}, lastUpdate)  VALUES  ('{1}', '{2}', '{3}', NOW())";
        let insertContactOfOffer: string = "INSERT INTO contacts(principalDomain, contacttypeId, {0}, offername, lastUpdate)  VALUES  ('{1}',{2},'{3}', '{4}', NOW())";
        // dummy werte entfernen
        item = this.cleanDummyvalues(item);
        if (item.tablename !== null && item.tablename !== undefined && item.tablename === "offers") {
            return this.stringservice.FormatString(insertOffer, [item.fieldname, item.principalDomain, item.offername, item.newvalue]);
        }
        else if (item.tablename !== null && item.tablename !== undefined && item.fieldidentity !== null && item.fieldidentity !== undefined && item.tablename === "contacts") {
            return this.stringservice.FormatString(insertContactOfOffer, [item.fieldname, item.principalDomain, item.fieldidentValue, item.newvalue, item.offername]);
        }

    }

    /**
     * Query um Angebot und seine Kontakten zu aktualisieren
     * @param item Änderungsobjekt
     */
    getUpdateSqlQueryOfOfferData(item: ChangedFieldObject) {

        let updateOffer: string = "UPDATE offers SET {0} = '{1}', lastUpdate=NOW() WHERE principalDomain = '{2}' AND offername = '{3}' AND  id = {4}";
        // Besonder-Fall um 0000-00-00 Datum bei publishedSince zu vermeiden
        if (item.fieldname === "publishedSince" && item.tablename === "offers" && (!this.stringservice.isUndefinedNullOrEmpty(item.oldvalue)) && (this.stringservice.isUndefinedNullOrEmpty(item.newvalue))) {
            item.newvalue = "NULL"
            updateOffer = "UPDATE offers SET {0} = {1}, lastUpdate=NOW() WHERE principalDomain = '{2}' AND offername = '{3}' AND  id = {4}";
        }

        //let updateOffer: string = "UPDATE offers SET {0} = '{1}', lastUpdate=NOW() WHERE principalDomain = '{2}' AND  id = {3}";
        // bei änderung der offername, spalte in contact auch anpassen bevor Kontakte speichern
        let updateContact: string = "UPDATE contacts SET {0} = '{1}', lastUpdate=NOW() WHERE principalDomain = '{2}' AND offername = '{3}' AND {4} = {5}";
        // dummy werte entfernen
        item = this.cleanDummyvalues(item);

        if (item.tablename !== null && item.tablename !== undefined) {
            if (item.tablename === "offers") {
                return this.stringservice.FormatString(updateOffer, [item.fieldname, item.newvalue, item.principalDomain, item.offername, item.offerRowId]);
                //return this.stringservice.FormatString(updateOffer, [item.fieldname, item.newvalue, item.principalDomain, item.offerRowId]);
            } else if (item.fieldidentity !== null && item.fieldidentity !== undefined && item.tablename === "contacts") {
                return this.stringservice.FormatString(updateContact, [item.fieldname, item.newvalue, item.principalDomain, item.offername, item.fieldidentity, item.fieldidentValue]);

            }

        }
    }

    /**
     * Fremdschlüssel in der Tabelle offer soll auch aktualisiert werden wenn die Kontakt-Daten erst später eingegeben wurden
     * @param item Änderungsobjekt
     */
    getUpdateSqlQueryOfTableOffer(item: ChangedFieldObject) {
        let field: string;
        if (item.tablename !== null && item.tablename !== undefined &&
            item.fieldidentity !== null && item.fieldidentity !== undefined &&
            item.tablename === "contacts" && item.fieldidentity === "contacttypeId") {
            if (item.fieldidentValue === contactTypes.reportOfficer.toString())
                field = "reportingofficerId";
            else if (item.fieldidentValue === contactTypes.firstReportRecipient.toString())
                field = "reportingreceiverOneId";
            else if (item.fieldidentValue === contactTypes.secondReportRecipient.toString())
                field = "reportingreceiverTwoId";
            else if (item.fieldidentValue === contactTypes.thirdReportRecipient.toString())
                field = "reportingreceiverThreeId";
        }


        if (!this.stringservice.isUndefinedNullOrEmpty(field))
            //return this.stringservice.FormatString("UPDATE offers SET {0} = {1}, lastUpdate=NOW() WHERE principalDomain = '{2}' AND offername = '{3}' AND  id = {4}", [field, item.fieldidentValue, item.principalDomain, item.offername, item.offerRowId])
            return this.stringservice.FormatString("UPDATE offers SET {0} = {1}, lastUpdate=NOW() WHERE principalDomain = '{2}' AND  id = {3}", [field, item.fieldidentValue, item.principalDomain, item.offerRowId])

    }

     /**
     * liefert die SQL-Abrfrage zurück, um das Feld export auf 1 zu aktualisieren
     * @param item, Änderungsobjekt      
     */
    getUpdateExportSqlQueryOfTableOffer(item: ChangedFieldObject) {

        // Besonder-Fallaktualisiert export
        if (item.fieldname === "publishedSince" && item.tablename === "offers" && (this.stringservice.isUndefinedNullOrEmpty(item.oldvalue)) && (!this.stringservice.isUndefinedNullOrEmpty(item.newvalue)))
            return this.stringservice.FormatString("UPDATE offers SET export = 1, lastUpdate=NOW() WHERE principalDomain = '{0}' AND offername = '{1}' AND  id = {2}", [item.principalDomain, item.offername, item.offerRowId])
        return "";

    }

    /**
     * liefert die SQL-Abrfrage zurück, um eine Änderung im Admin-Bereich als bearbeitet zu kennzeichnen
     * @param item, Änderungsobjekt 
     * @param userDataChangesState, der Bearbeitungsstatus 
     */
    getProcessedSqlQuery(item: ChangedFieldObject, userDataChangesState: string) {
        if (item.id !== null && item.id !== undefined) {
            let processedBy: string = JSON.parse(this.tokenService.getLoggedInUser()).username;
            if (processedBy !== null && processedBy !== undefined)
                processedBy = processedBy.replace("-", "");

            return this.stringservice.FormatString("UPDATE userChanges SET processed = '{0}', processedBy='{1}', lastUpdate=NOW() WHERE id = {2}", [userDataChangesState, processedBy, item.id]);
        }
        return null;
    }

    /**
     * liefert anhand eines Ids die SQL-Abrfrage zurück, um eine Änderung im Admin-Bereich als bearbeitet zu kennzeichnen
     * @param userChangesId 
     * @param userDataChangesState 
     */
    getProcessedChangeSqlQuery(userChangesId: string, userDataChangesState: string) {

        let processedBy: string = JSON.parse(this.tokenService.getLoggedInUser()).username;
        if (processedBy !== null && processedBy !== undefined)
            //TODO: schauen, warum string in where Klausel crasht
            processedBy = processedBy.replace("-", "");
        return this.stringservice.FormatString("UPDATE userChanges SET processed = '{0}', processedBy='{1}', lastUpdate=NOW() WHERE id = {2}", [userDataChangesState, processedBy, userChangesId]);
    }



}