
    
    <h4 align="center"><br /> Passwort vergessen<br /><br /></h4>
<div class="col-sm-8 col-sm-offset-2">

    <form [formGroup]="accountRecoveryForm" (ngSubmit)="onSubmit()">
        <div class="form-group">
            <label for="email" class="required"> Benutzername</label>
            <input type="text" id="username" class="form-control" formControlName="username">
        </div>
        <!--
        <div class="form-group">

            <label for="password" class="required"> E-Mail</label>
            <input type="text" id="email" class="form-control" formControlName="email">
        </div>       
        -->
        <br /> <br />
        <p align="center">
            <button class="btn btn-outline-primary" type="button" (click)="cancelOption()">Abbrechen</button>&nbsp;
            <button class="btn btn-outline-primary" [disabled]="accountRecoveryForm.invalid" data-toggle="tooltip"
                data-placement="top" title="Passwort anfordern">Anfrage Senden</button>&nbsp;


        </p>
    </form>
</div>
<p class="text-danger">{{errorMessage}}</p>

