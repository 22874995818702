import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { OfferService } from 'src/app/services/offer.service';
import { AuthService } from 'src/app/services/auth.service';
import { Component, OnInit, Inject } from '@angular/core';
import { Offer } from 'src/app/models/Offer.model';
import { Router } from '@angular/router';


/**
 * Komponent-Klasse zum Anzeigen von Modal mit Warnungen, dass ein Angebot gelöscht würde
 */

@Component({
  selector: 'offerdeletionconfirmation',
  templateUrl: './offerdeletionconfirmation.component.html',
  styleUrls: ['./offerdeletionconfirmation.component.css']
})
export class OfferdeletionconfirmationComponent implements OnInit {

  constructor(private authService: AuthService, private offerService: OfferService, private router: Router,
    public dialogRef: MatDialogRef<OfferdeletionconfirmationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Offer) { }

  ngOnInit() {

  }

  /**
   *  Entfernt ein Angebot aus
   */
  deleteOption() {
    this.authService.isUserTokenStillValid().subscribe((istokenValidResponse) => {
      if (istokenValidResponse) {
        this.offerService.delete(this.data)
          .subscribe((response) => {
            // Refresh page
            this.router.navigateByUrl('blank').then(() => {
              this.router.navigate(['offerlist', this.data.principalDomain]);
            });
          }
          );
        this.closeModal();
      }
    },

      (tokenerror) => {
        this.authService.signOutUser();
      });

  }


  closeModal(): void {
    this.dialogRef.close();
  }



}
