
import { environment } from 'src/environments/environment';
import { Offer } from "src/app/models/Offer.model";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";


/**
 * Der Service spricht der API, um diverse Operationen über die Angebote zu führen
 */

@Injectable({
  providedIn: "root"
})
export class OfferService {

  private apiurl = environment.apiRootDirectory;


  constructor(private _http: HttpClient) { }

  /**
   * liefert die Angebote eines Mitglieds zurück
   * @param principalDomain Mitgliedskürzel
   */
  readOffers(principalDomain: string) {
    return this._http.post<Offer[]>(this.apiurl + "data/offers/readOffersByCustomer.php", {
      "principalDomain": principalDomain
    });
  }

  

  /**
   * liefert ein einziges Angebot zurück
   * @param principalDomain 
   * @param offername 
   */
  readSingleOffer(principalDomain: string, offername: string) {
    return this._http.post<Offer>(this.apiurl + "data/offers/readSingleOffer.php", {
      "principalDomain": principalDomain,
      "offername": offername
    });
  }

  /**
   * ordnet ein Lizenzner zu
   * @param principalDomain, Mitgliedskürzel
   * @param marketerId, Vermarkter-Id 
   */
  addMarketerId(principalDomain: string, marketerId: number) {

    return this._http.post<any>(this.apiurl + "data/offers/addMarketerid.php", {
      "principalDomain": principalDomain,
      "marketerId": marketerId

    });
  }

  /**
   * aktiviert oder deaktiviert ein Angebot
   * @param offer, das Angebot 
   * @param action, die Operation activate|deactivate 
   */
  activateOrDeactivate(offer: Offer, action: string) {

    return this._http.post<any>(this.apiurl + "data/offers/activateOrDeactivate.php", {
      "principalDomain": offer.principalDomain,
      "offername": offer.offername,
      "action": action
    });
  }

  /**
   * löscht ein Angebot
   * @param offer das Angebot
   */
  delete(offer: Offer) {

    return this._http.post<any>(this.apiurl + "data/offers/delete.php", {
      "principalDomain": offer.principalDomain,
      "offername": offer.offername
    });
  }

  /**
   * legt ein Angebot
   * @param offer das Angebot
   * @param isAvdertisingprovider gib an, ob das Angebot ein Werbungsträger ist
   */
  initOffer(offer: Offer, offerIdentifier: string, isAvdertisingprovider: any) {

    return this._http.post<any>(this.apiurl + "data/offers/initOffer.php", {
      "principalDomain": offer.principalDomain,
      "offername": offer.offername,
      "offerIdentifier": offerIdentifier,
      "offertyp": offer.offertyp,
      "reportingLevel": offer.reportingLevel,
      "ismobil": offer.mobiltypeId,
      "ftp": offer.ftp,
      "scp": offer.scp,
      "isAvdertisingprovider": isAvdertisingprovider

    });
  }

  addPublisehdsince(principalDomain: string, offername: string, publishedSinceDate: string) {
    return this._http.post<any>(this.apiurl + "data/offers/addPublishedsince.php", {
      "principalDomain": principalDomain,
      "offername": offername,
      "publishedSince": publishedSinceDate

    });
  }

  /**
   * setzt die 25% Regel
   * @param principalDomain Mitgliedskürzel
   * @param offername Angebotsname
   */
  addPercentrules(principalDomain: string, offername: string) {
    return this._http.post<any>(this.apiurl + "data/offers/addRulesPercent.php", {
      "principalDomain": principalDomain,
      "offername": offername

    });
  }

  /**
   * speichert die Zuzählung
   * @param offer, das Angebot 
   * @param additionalsite die Zuzählung
   */
  insertAdditionalsite(offer: Offer, additionalsite: string) {
    return this._http.post<any>(this.apiurl + "data/offers/insertAdditionalsite.php", {
      "principalDomain": offer.principalDomain,
      "offername": offer.offername,
      "additionalsite": additionalsite

    });
  }

  /**
   * liefert die Einzelangebote eines Änderungsantrages
   * @param principalDomain Mitgliedskürzel
   */
  selectActiveOffersOfReport(principalDomain: string) {
    return this._http.post<any>(this.apiurl + "data/offers/readActiveOffersOfReport.php", {
      "principalDomain": principalDomain

    });
  }


}
