/**
 * Modelklasse des Angebotsojekts
 */
export class Offer{
constructor(            
            public rowId: number,
            public principalDomain: string,
            public offername: string,
            public offertyp: string,
            public url: string,
            public pricelist: string,
            public reportingofficer: string,
            public reportingLevel: string,
            public ftp: string,
            public scp: string,
            public ftp_server: string,
            public ftp_port: string,
            public ftp_directory: string,
            public ftp_user: string,
            public ftp_password: string,
            public recipientOne: string,
            public recipientTwo: string,
            public recipientThreee: string,   
            public mobiltypeId: Number,
            public isactive: string    
            ){}
            }
