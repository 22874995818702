import { Injectable } from "@angular/core";
import data from "src/assets/config.json";

/**
 * Der Service liefert die Regexe zurück
 */
@Injectable({
    providedIn: "root"
})
export class ValidatorPatternService {

    constructor() { }

    /**
     *  liefert der Regex der Personnamen (Kontakten Z. Bsp.)
     */
    get regexOfPersonname(): string {
        if (data && data.configuration && data.configuration.regex && data.configuration.regex.personname)
            return data.configuration.regex.personname;
        return undefined;
    }
    /**
     * liefert der Regex des Firmennamens
     */
    get regexOfCompanyname(): string {
        if (data && data.configuration && data.configuration.regex && data.configuration.regex.companyname)
            return data.configuration.regex.companyname;
        return undefined;
    }

    /**
     * liefert der Regex der Telefonnummer
     */
    get regexOfPhone(): string {
        if (data && data.configuration && data.configuration.regex && data.configuration.regex.phone)
            return data.configuration.regex.phone;
        return undefined;
    }

    /**
     * liefert der Regex der Stadt
     */
    get regexOfCity(): string {
        if (data && data.configuration && data.configuration.regex && data.configuration.regex.city)
            return data.configuration.regex.city;
        return undefined;
    }

    /**
     * liefert der Regex der PLZ
     */
    get regexOfZipcode(): string {
        if (data && data.configuration && data.configuration.regex && data.configuration.regex.zipcode)
            return data.configuration.regex.zipcode;
        return undefined;
    }

    /**
     * liefert der Regex der Strasse
     */
    get regexOfStreet(): string {
        if (data && data.configuration && data.configuration.regex && data.configuration.regex.street)
            return data.configuration.regex.street;
        return undefined;
    }

    /**
     * liefert der Regex des Usernamens
     */
    get regexOfUsername(): string {
        if (data && data.configuration && data.configuration.regex && data.configuration.regex.username)
            return data.configuration.regex.username;
        return null;
    }

    regexOfLocalOrApp(offerIsMobil: boolean): string {
        if (offerIsMobil)
            return this.regexOAppname;

        return this.regexOfLocal;
    }

    /**
     * liefert der Regex des Lokals
     */
    get regexOfLocal(): string {
        if (data && data.configuration && data.configuration.regex && data.configuration.regex.local)
            return data.configuration.regex.local;
        return null;
    }

    /**
     * liefert der Regex des Apps
     */
    get regexOAppname(): string {
        if (data && data.configuration && data.configuration.regex && data.configuration.regex.appname)
            return data.configuration.regex.appname;
        return null;
    }

    /**
     * liefert der Regex des Passworts
     */
    get regexOfPasswordChars(): string {
        //Regex fur Passwort-Änderung geeignet, da man dabei die ungültige Zeichen zeigen kann. Die Länge wird nicht mit Regex geprüft
        if (data && data.configuration && data.configuration.regex && data.configuration.regex.passwordChars)
            return data.configuration.regex.passwordChars;
        return null;
    }

    /**
     * liefert der Regex des Urls
     */
    get regexOfUrl(): string {
        //Regex fur Passwort-Änderung geeignet, da man dabei die ungültige Zeichen zeigen kann. Die Länge wird nicht mit Regex geprüft
        if (data && data.configuration && data.configuration.regex && data.configuration.regex.url)
        {            
            return data.configuration.regex.url;
        }
        return null;
    }

    /**
     * liefert der Regex des FTP-Servers
     */
    get regexOfFtpServer(): string {
        if (data && data.configuration && data.configuration.regex && data.configuration.regex.ftpServer)
            return data.configuration.regex.ftpServer;
        return null;
    }

    /**
     * liefert der Regex des FTP-Ports
     */
    get regexOfFtpPort(): string {
        if (data && data.configuration && data.configuration.regex && data.configuration.regex.ftpPort)
            return data.configuration.regex.ftpPort;
        return null;
    }

    /**
     * liefert der Regex des FTP-Verzeichnisses
     */
    get regexOfFtpDirectory(): string {
        if (data && data.configuration && data.configuration.regex && data.configuration.regex.ftpDirectory)
            return data.configuration.regex.ftpDirectory;
        return null;
    }

    /**
     * liefert der Regex des FTP-Users
     */
    get regexOfFtpUser(): string {
        if (data && data.configuration && data.configuration.regex && data.configuration.regex.ftpUser)
            return data.configuration.regex.ftpUser;
        return null;
    }

    /**
     * liefert der Regex des Angebotsnamens
     */
    get regexOfOffername(): string {
        if (data && data.configuration && data.configuration.regex && data.configuration.regex.offername)
            return data.configuration.regex.offername;
        return null;
    }

    /**
     * liefert der Regex des FTP-Passworts
     */
    get regexOfFtpPassword(): string {
        if (data && data.configuration && data.configuration.regex && data.configuration.regex.ftpPassword)
            return data.configuration.regex.ftpPassword;
        return null;
    }

    /**
     * liefert der minimalen Zeichenanzahl des Passworts
     */
    get getPasswordMinLength() {

        if (data && data.configuration) {
            if (data.configuration.password && data.configuration.password.minLength) {
                return data.configuration.password.minLength;
            }
            return 8;

        }
    }

    /**
     * liefert der maximalen Zeichenanzahl des Passworts
     */
    get getPasswordMaxLength() {

        if (data && data.configuration) {
            if (data.configuration.password && data.configuration.password.maxLength) {
                return data.configuration.password.maxLength;
            }
            return 30;

        }
    }

}