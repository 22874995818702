
import { DocumentService } from "src/app/services/document.service";
import { SafeResourceUrl } from "@angular/platform-browser";
import { AuthService } from "src/app/services/auth.service";
import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

/**
 * Komponent-Klasse zum Download Vertrag zur Auftragsverarbeitung
 */

@Component({
  selector: "pdfcontract",
  templateUrl: "./pdfcontract.component.html",
  styleUrls: ["./pdfcontract.component.scss"]
})
export class PdfcontractComponent implements OnInit {
  // Fehler
  errorMessage: string
  // Seitenumber
  page: number = 1;
  // Pdf Stream
  pdfSrc: SafeResourceUrl;

  constructor(private authService: AuthService, private router: Router, private docService: DocumentService) { }

  ngOnInit() {

    this.authService.isUserTokenStillValid().subscribe(isTokenValid => {

      if (isTokenValid) {
        // Service-Aufruf
        this.docService.downloadAdditionalAgreementPdf().subscribe((downloadresponse) => {

          let blob: Blob = new Blob([downloadresponse], { type: "application/pdf" });

          // Anzeige im Browser
          // falls IE Broswer
          const navigator =  (window.navigator as any);
          if (window.navigator && navigator.msSaveOrOpenBlob) {
            navigator.msSaveOrOpenBlob(blob, "oewa_additional_agreement.pdf");
          }
          // andere Browsers
          else {

            var objectUrl = URL.createObjectURL(blob);
            // Route zurücksetzen
            this.router.navigateByUrl("blank").then(() => {
              this.router.navigate(["agreementpreview"]);
            });
            //download in neue Tab öffnen
            window.open(objectUrl, "_blank");

          }
        })          
      }
     
    });



  }
}
