
import { environment } from 'src/environments/environment';
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

/**
 * Der Service spricht der API an, um die Mobiletype von Angeboten zu liefern
 */

@Injectable({
    providedIn: "root"
})
export class MobiletypesService {

    private apiurl = environment.apiRootDirectory;

    constructor(private _http: HttpClient) { }

    /**
     * liefert die Mobiletype zurück
     */
    readMobiletypes() {        
            return this._http.get<any>(this.apiurl + "data/mobiletypes/read.php");        
    }
}