<!-- Komponent derzeit NICHT verwendet. Steht hier für eine Simulation des Bestellcenters der ÖWA -->
<table border="0"><tr><td width="50%">
<br><br>
<mat-card class="fitordercenterPage">
    <mat-card-title>
        <span> IO Measurement </span>&nbsp;&nbsp;
        <button class="btn btn-outline-primary btn-sm" (click)="showDetails(0)" data-toggle="tooltip"
            data-placement="top" title="Beschreibung"> <i class="fa fa-question-circle" aria-hidden="true"></i></button>
    </mat-card-title>
    <mat-card-subtitle>Buchung</mat-card-subtitle>
    <mat-card-actions>
        <br><br>

        <div style="margin-left: 30px;" class="form-group">

            <form [formGroup]="iomBookingForm" (keydown.enter)="$event.preventDefault()"
                (ngSubmit)="onIomBookingSubmit()">


                <table width="40%">
                    <tr>
                        <td>
                            <table>
                                <tr class="styleHeader"><td>Angebotsname</td><td>Angebotstyp</td><td>IOM-Zustand</td></tr>
                                <tr *ngFor="let offer of offers;let indexOfelement=index;" [ngClass]="getStyle(indexOfelement)">
                                    <td  width="40%">

                                        <app-checkbox [offerid]="offer.offername" [websitename]="offer.websitename"
                                            [isChecked]="offer.published" [isCheckedInit]="offer.published"
                                            [color]="getColor(offer)" (customOnChange)="getChangeHandler($event)"></app-checkbox>

                                    </td>
                                    <td width="30%">
                                            {{offer.offertypdescription}}
                                    </td>
                                    <td width="50%"  [ngClass]="getStateStyle(states[offer.offername])">
                                        {{states[offer.offername]}}
                                </td>
                                </tr>
                            </table>

                        </td>

                    </tr>
                    <tr>
                        
                        <td colspan="2">
                            <br>
                            <label class="required" for="bookingStarDate">Start der IOMessung</label>
                            <input type="date" class="form-control col-sm-4" name="bookingStarDate"
                                formControlName="bookingStarDate" [(ngModel)]="bookingStarDate"><br>
                        </td>

                    </tr>

                </table>


                <br><br>
                <button class="btn btn-outline-primary" type="submit" [disabled]="appliedOffers.length===0 &&  cancelledOffers.length===0">
                    <i class="fa fa-save"></i>&nbsp;Beantragen</button>&nbsp;&nbsp;&nbsp;
                <button class="btn btn-outline-primary" type="button" (click)="cancel()">Abbrechen</button>
            </form>

        </div>

    </mat-card-actions>
</mat-card>
</td>
<td width="30%" >

    <mat-card class="fitordercenterPageSum" *ngIf="appliedOffers.length > 0">
        <mat-card-title>
            <span> IOM</span>&nbsp;&nbsp;
            <button class="btn btn-outline-primary btn-sm" (click)="showDetails(0)" data-toggle="tooltip"
                data-placement="top" title="Beschreibung"> <i class="fa fa-question-circle" aria-hidden="true"></i></button>
        </mat-card-title>
        <mat-card-subtitle>beantragend</mat-card-subtitle>
        <mat-card-actions>
            <table>
                <tr *ngFor="let offer of descrAppliedOffers;let indexOfelement=index;" [ngClass]="getStyle(indexOfelement)">
                    <td>{{offer}}</td></tr>
            </table>
        </mat-card-actions>
    </mat-card>
<br><br>
    <mat-card class="fitordercenterPageSum" *ngIf="cancelledOffers.length > 0">
        <mat-card-title>
            <span> IOM </span>&nbsp;&nbsp;
            <button class="btn btn-outline-primary btn-sm" (click)="showDetails(0)" data-toggle="tooltip"
                data-placement="top" title="Beschreibung"> <i class="fa fa-question-circle" aria-hidden="true"></i></button>
        </mat-card-title>
        <mat-card-subtitle>kündigend</mat-card-subtitle>
        <mat-card-actions>
            <table>
                <tr *ngFor="let offer of descrCancelledOffers;let indexOfelement=index;" [ngClass]="getStyle(indexOfelement)">
                    <td>{{offer}}</td></tr>
            </table>
            
        </mat-card-actions>
    </mat-card>

</td></tr></table>

