import { StringFunctionsService } from './stringFunctions.service';
import { GenericDisplaySservice } from './genericDisplay.service';
import messageToken from "src/assets/messagetokens.json";
import config from "src/assets/config.json";
import { Injectable } from "@angular/core";
import CryptoJS from 'crypto-js';

/**
 * Der Service verschlüsselt und entschlüsselt die Daten
 */


@Injectable({
  providedIn: "root"
})
export class CryptoService {

  private encryptSecretKey = config.configuration.angularcrypto.encryptkey;

  constructor(private genericDisplayService: GenericDisplaySservice, private stringService: StringFunctionsService) {
  }

  /**
   *  entschlüsselt die Daten
   * @param dataToEncrypt, der Daten 
   */
  encryptData(dataToEncrypt: string) {

    try {
      if (!this.stringService.isUndefinedNullOrEmpty(dataToEncrypt))
        return CryptoJS.AES.encrypt(JSON.stringify(dataToEncrypt), this.encryptSecretKey).toString();
      return dataToEncrypt;
    } catch (e) {
      this.genericDisplayService.showAlert(messageToken.headererrormodal, e + ", encrypted is " + dataToEncrypt)
    }
  }

  /**
   * verschlüsselt die Daten
   * @param encryptedData 
   */
  decryptData(encryptedData: string) {

    try {
      if (!this.stringService.isUndefinedNullOrEmpty(encryptedData)) {
        const bytes = CryptoJS.AES.decrypt(encryptedData, this.encryptSecretKey);

        if (bytes.toString()) {          
          return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

        }
      }
      return encryptedData;
    } catch (e) {
      
      // weiter recherchieren warum 'Malformed UTF-8 data' erscheint obwohl der wert richtig ist
      if (e.toString().includes('Error: Malformed UTF-8 data'))
        {
          //this.genericDisplayService.showAlert(messageToken.headererrormodal, e + ", decrypted is " + encryptedData)
        }
    }
  }
}