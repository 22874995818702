import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit, Inject } from '@angular/core';


/**
 * Komponent-Klasse zum Anzeigen von Modal mit Warnungen
 */

@Component({
  selector: 'genericmodaldisplay',
  templateUrl: './genericmodaldisplay.component.html',
  styleUrls: ['./genericmodaldisplay.component.css']
})
export class GenericmodaldisplayComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<GenericmodaldisplayComponent>,  @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
  }
  cancelOption() {
    this.dialogRef.close();
  }
}
