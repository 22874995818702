import { Token } from '@angular/compiler';
import { Component, OnInit } from '@angular/core';
import { FormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { iomBookingState } from 'src/app/enumerations/iomBookingState.enum';
import { iomBookingStateColor } from 'src/app/enumerations/iomBookingStateColor.enum';
import { Offer } from 'src/app/models/Offer.model';
import { AuthService } from 'src/app/services/auth.service';
import { CustomerService } from 'src/app/services/customer.service';
import { DatastoreService } from 'src/app/services/dataStore.service';
import { GenericDisplaySservice } from 'src/app/services/genericDisplay.service';
import { OfferService } from 'src/app/services/offer.service';
import { TokenService } from 'src/app/services/token.service';

@Component({
  selector: 'app-ordercenter',
  templateUrl: './ordercenter.component.html',
  styleUrls: ['./ordercenter.component.css']
})
export class OrdercenterComponent implements OnInit {


  iomBookingForm: UntypedFormGroup;
  offers: any[];
  bookingStarDate: string;
  appliedOffers: string[] = []
  descrAppliedOffers: string[] = []
  alreadyAppliedOffers: string[] = []
  cancelledOffers: string[] = []
  descrCancelledOffers: string[] = []
  states: {} = {};


  constructor(private dataStoreService: DatastoreService, private authService: AuthService, private offerService: OfferService, private cutomerService: CustomerService, private formBuilder: UntypedFormBuilder, private tokenService: TokenService, private router: Router, private genericDisplaySservice: GenericDisplaySservice) { }

  ngOnInit(): void {

    this.bookingStarDate = this.tokenService.addDaysToDate(new Date(), 0);

    // Initialisierung des Formulars mit den Validatoren
    // Formular neues Mitgied anlegen
    this.iomBookingForm = this.formBuilder.group(
      {
        bookingStarDate: ""
      });



    // Aufruf des Services zur Prüfung ob das Token noch valide ist
    this.authService.isUserTokenStillValid().subscribe(isTokenValid => {
      if (isTokenValid) {
        this.offerService.readOffers(this.dataStoreService.getDataFromLocalstorage("principalDomainSelected")).subscribe((response) => {
          this.offers = [];
          for (let obj of response['data']) {

            this.offers.push(obj)

            if (obj.published == 1)
              this.alreadyAppliedOffers.push(obj.offername);

          }
        })
      }
    });

  }

  /**
   * IOM Beantragen oder verlanssen
   */
  addOrRemove(jsonObj: any) {

    //ich möchte die IOM Messung verlassen
    if (jsonObj.state !== undefined && jsonObj.state.length !== 0 && jsonObj.state == iomBookingState.cancelling) {
      this.cancelledOffers.push(jsonObj.offerid)
    }
    //ich möchte die IOM Messung beantragen
    else if (jsonObj.state !== undefined && jsonObj.state.length !== 0 && jsonObj.state == iomBookingState.applying) {
      this.appliedOffers.push(jsonObj.offerid)
    }// ich wollte kündigen. Ich möchte aber in der IOM Messung weiterbleiben
    else if (jsonObj.state !== undefined && jsonObj.state.length !== 0 && jsonObj.state == iomBookingState.confirmed) {
      //this.appliedOffers = this.appliedOffers.filter(offerid => offerid.toString() != jsonObj.offeridd.toString())
      this.cancelledOffers = this.cancelledOffers.filter(offerid => offerid.toString() != jsonObj.offerid.toString())

    } // ich wollte mich engagieren, aber ich will es nicht mehr
    else if (jsonObj.state == undefined || (jsonObj.state != undefined && jsonObj.state.length === 0)) {
      this.appliedOffers = this.appliedOffers.filter(offerid => offerid.toString() != jsonObj.offerid.toString())
      //this.cancelledOffers = this.cancelledOffers.filter(offerid => offerid.toString() != jsonObj.offerid.toString())

    }

    this.descrAppliedOffers = this.offerIdToWebsitename(this.appliedOffers);
    this.descrCancelledOffers = this.offerIdToWebsitename(this.cancelledOffers);
  }


  offerIdToWebsitename(list: string[]) {
    let result: string[] = [];
    for (let o of this.offers)
      if (list.includes(o.offername))
        result.push(o.websitename)

    return result;

  }

  getStateStyle(state: string) {
    //if (state == "IOM Kündingung erwünscht" || state == "gerade beantragt")
    if (state == iomBookingState.cancelling || state == iomBookingState.applying)
      // aus der css Datei des Komponents
      return "stateStyle";

  }

  getColor(offer: any) {
    // TODO sollte Attribut für booked 
    if (offer.published == 1)
      //return "#0B0B61";
      return iomBookingStateColor.confirmed;

  }


  onIomBookingSubmit() {
    console.log(this.appliedOffers)
    console.log(this.cancelledOffers)

  }


  cancel() {
    //Ansicht aktualisieren
    this.router.navigateByUrl("blank").then(() => {
      this.router.navigate(["/"]);
    });
  }


  getChangeHandler(jsonObj: any) {
    this.states[jsonObj.offerid] = jsonObj.state

    if (undefined !== jsonObj && jsonObj.myevent !== undefined && jsonObj.myevent.target !== undefined && (true === jsonObj.myevent.target.checked || false === jsonObj.myevent.target.checked))
      this.addOrRemove(jsonObj)

  }

  getStyle(index: number) {
    if (index % 2 === 0)
      return "ordercenterStyle";

  }

  showDetails(service: number) {
    // die Warnung anzeigen
    this.genericDisplaySservice.showAlert("IOM Buchung", "Erläuterung: " + ".......", null, 1);

  }
}
