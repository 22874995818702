import { iomBookingStateColor } from "src/app/enumerations/iomBookingStateColor.enum";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { iomBookingState } from "src/app/enumerations/iomBookingState.enum";

@Component({
  selector: "app-checkbox",
  templateUrl: "./checkbox.component.html",
  styleUrls: ["./checkbox.component.css"]
})
export class CheckboxComponent {
  @Input()
  offerid: string;
  @Input()
  websitename: string;
  @Input()
  state: string;
  @Input()
  color: string;
  @Input()
  isChecked: boolean;
  @Output()
  customOnChange = new EventEmitter<any>();
  @Input()
  isCheckedInit: boolean;

  ngOnInit(): void {
    this.onChangeHandler(undefined)
    this.customOnChange.emit({ "myevent": undefined, "state": this.state, "offerid": this.offerid });
  }

  onChangeHandler($event:any) {
    //"#0B0B61"

    if (undefined !== $event) {
      if (true == $event.target.checked && !this.isCheckedInit) {
        this.color = iomBookingStateColor.applying // "red"
        this.isChecked = true;
        this.state = iomBookingState.applying; // "gerade beantragt"
      } else if (false == $event.target.checked && !this.isCheckedInit) {
        this.color = iomBookingStateColor.noState; //"#ccc"
        this.isChecked = false;
        this.state = iomBookingState.noState; // undefined

      } else if (true == $event.target.checked && this.isCheckedInit) {
        this.color = iomBookingStateColor.confirmed; //"#0B0B61"
        this.isChecked = true;
        this.state = iomBookingState.confirmed;  //"IOM aktiv"
      } else if (false == $event.target.checked && this.isCheckedInit) {
        this.color = iomBookingStateColor.cancelling; // "red"
        this.isChecked = true;
        this.state = iomBookingState.cancelling; // "IOM Kündingung erwünscht"

      }
    }
     else if (undefined === $event && this.isCheckedInit === true) {
      this.state = iomBookingState.confirmed; //"IOM aktiv"
    }

    this.customOnChange.emit({ "myevent": $event, "state": this.state, "offerid": this.offerid });
  }


  addOrRemove($event: any) {

    this.customOnChange.emit({ "myevent": $event, "state": this.state, "offerid": this.offerid });

  }



}
