<br>
<div class="fitmemberprofilPage">
    <table>
        <tr>
            <td>
                <mat-accordion>
                    <br>

                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <b>
                                <li> Mitgliedschaft</li>
                            </b>
                        </mat-expansion-panel-header>

                        <div class="form-group">


                            <table border="1">
                                <tr class="trstyle">
                                    <td>ist das Mitglied ist ein mobiles?</td>
                                    <td [ngClass]="getStyle(member.isMobilUser)">{{member.isMobilUser}}</td>
                                <tr>
                                    <td>hat das Mitglied ein Dachangebot?</td>
                                    <td [ngClass]="getStyle(member.hasOA)">{{member.hasOA}}</td>
                                </tr>
                                <tr class="trstyle">
                                    <td>ist Das Mitglied ein Vermarkter?</td>
                                    <td [ngClass]="getStyle(member.isMarketer)">{{member.isMarketer}}</td>
                                </tr>
                                <tr>
                                    <td>ist das Mitglied ein Werbungsträger?</td>
                                    <td [ngClass]="getStyle(member.isAvdertisingprovider)">
                                        {{member.isAvdertisingprovider}}</td>
                                </tr>
                                <tr class="trstyle" *ngIf="member.isMobilUser=='JA'">
                                    <td>zugehörige Mobilangebotstypen</td>
                                    <td class="neutralStyle">{{member.mobiletypes.data}}</td>
                                </tr>

                            </table>

                        </div>

                    </mat-expansion-panel>

                    <br><br>

                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <b>
                                <li> Kontoinformation</li>
                            </b>
                        </mat-expansion-panel-header>

                        <div class="form-group">
                            <table border="1">
                                <tr>
                                    <td>Kontotyp</td>
                                    <td>{{member.accounttype}}</td>
                                </tr>
                                <tr class="trstyle">
                                    <td>E-Mail</td>
                                    <td>{{member.email}}</td>
                                </tr>
                                <tr>
                                    <td>Mitgliedskürzel</td>
                                    <td>{{member.principalDomain}}</td>
                                </tr>
                                <tr class="trstyle">
                                    <td>Mitgliedsloginname</td>
                                    <td>{{member.username}}</td>
                                </tr>

                            </table>
                        </div>
                    </mat-expansion-panel>

                    <br><br>
                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <b>
                                <li> Gebuchte laufende Reportübermittlung</li>
                            </b>
                        </mat-expansion-panel-header>

                        <div class="form-group">

                            <table class="table-responsive-xl table-striped" width="100%" cellspacing="0">
                                <thead>
                                    <tr align="center" *ngIf="reports.length > 0">
                                        <td id="styleOffername">Angebotsname</td>
                                        <td id="styleOffername">Angebotstyp</td>
                                        <td id="styleOffername">Übermittlungshäufigkeit</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngIf="reports.length == 0">
                                        <td colspan="3" align="center">keine gebuchte Reportübermittlung</td>
                                    </tr>
                                    <tr align="center" *ngFor="let offer of reports">
                                        <td>{{offer.websitename}}</td>
                                        <td>{{offer.offertypdescription}}</td>
                                        <td>{{offer.reportingLevel}}</td>
                                    </tr>
                                </tbody>

                            </table>
                        </div>

                    </mat-expansion-panel>
                    <br><br>
                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <b>
                                <li> Angebot IOM</li>
                            </b>
                        </mat-expansion-panel-header>

                        <div class="form-group">


                        </div>

                    </mat-expansion-panel>

                    <br><br>

                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <b>
                                <li> Unbearbeitete Datenänderungen</li>
                            </b>
                        </mat-expansion-panel-header>

                        <div class="form-group">
                            <p *ngIf=" this.dataSource.data.length==0"> Keine Datenänderungen liegen vor.</p>
                            <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8"
                                *ngIf=" this.dataSource.data.length > 0">

                                <ng-container matColumnDef="principalDomain">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="style"> </th>
                                    <td mat-cell *matCellDef="let element">

                                        <p *ngIf="element.customerdataChangesfields.length > 0 || element.offerdataChangesfields.length > 0 || element.localdataChangesfields.length > 0"
                                            align="left" style="color: green;" data-toggle="tooltip"
                                            data-placement="top"><b> {{element.principalDomain}} </b></p>

                                        <table class="table-responsive-xl table-striped" cellspacing="0">
                                            <thead>
                                                <tr *ngIf="element.customerdataChangesfields.length > 0">
                                                    <td colspan="3"><b> {{element.changeDescription}} </b></td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngIf="element.customerdataChangesfields.length > 0">
                                                    <td id="styleOfDataCat" colspan="5">Allgemeine Informationen</td>
                                                </tr>
                                                <tr *ngIf="element.customerdataChangesfields.length > 0"
                                                    id="adminPageColumnstyle">
                                                    <td id="tdspace">Bezug</td>
                                                    <td id="tdspace"> Feldname </td>
                                                    <td id="tdspace">geändert am</td>

                                                </tr>
                                                <tr *ngFor="let changed of element.customerdataChangesfields">
                                                    <td id="tdspace">{{changed.target}}</td>
                                                    <td id="tdspace"> {{changed.changedFieldDesc}} </td>
                                                    <td id="tdspace">{{changed.changeDate}} </td>

                                                </tr>

                                                <tr *ngIf="element.offerdataChangesfields.length > 0">
                                                    <td id="styleOfDataCat" colspan="3">Angebotsänderungen</td>
                                                </tr>

                                                <tr *ngFor="let changed of element.offerdataChangesfields">
                                                    <td id="particularRow" colspan="3">
                                                        <table class="table-responsive-xl table-striped" width="100%"
                                                            cellspacing="0">
                                                            <thead>
                                                                <tr *ngIf="changed.groupedDataChangesfields.length > 0">
                                                                    <td id="styleOffername" colspan="3">
                                                                        {{changed.websitename}}</td>
                                                                </tr>


                                                                <tr *ngIf="element.offerdataChangesfields.length > 0"
                                                                    id="adminPageColumnstyle">
                                                                    <td id="tdspace">Bezug</td>
                                                                    <td id="tdspace"> Feldname </td>
                                                                    <td id="tdspace">geändert am</td>

                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr
                                                                    *ngFor="let grouped of changed.groupedDataChangesfields">

                                                                    <td id="tdspace">{{grouped.target}}</td>
                                                                    <td id="tdspace"> {{grouped.changedFieldDesc}} </td>
                                                                    <td id="tdspace">{{grouped.changeDate}} </td>

                                                                </tr>

                                                            </tbody>

                                                        </table>
                                                    </td>
                                                </tr>


                                                <tr *ngIf="element.localdataChangesfields.length > 0">
                                                    <!--<td colspan="7" id="styleOfDataCat">Localänderungen</td>-->
                                                    <td colspan="3" id="styleOfDataCat">
                                                        {{element.localOrAppChangeDescr}}</td>
                                                </tr>

                                                <tr *ngFor="let changed of element.localdataChangesfields">
                                                    <td id="particularRow" colspan="3">
                                                        <table class="table-responsive-xl table-striped" width="100%"
                                                            cellspacing="0">
                                                            <thead>
                                                                <tr *ngIf="changed.groupedDataChangesfields.length > 0">
                                                                    <td id="styleOffername" colspan="3">
                                                                        {{changed.websitename}}</td>
                                                                </tr>

                                                                <tr *ngIf="changed.groupedDataChangesfields.length > 0"
                                                                    id="adminPageColumnstyle">
                                                                    <td>Bezug</td>
                                                                    <td id="tdspace"> Feldname </td>

                                                                    <td id="tdspace">geändert am</td>

                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr
                                                                    *ngFor="let grouped of changed.groupedDataChangesfields">

                                                                    <td id="tdspace">{{grouped.target}}</td>
                                                                    <td id="tdspace"> {{grouped.changedFieldDesc}} </td>

                                                                    <td id="tdspace">{{grouped.changeDate}} </td>

                                                                </tr>

                                                            </tbody>
                                                        </table>
                                                    </td>
                                                </tr>


                                            </tbody>
                                        </table>
                                    </td>


                                </ng-container>

                                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                <!-- triped Row gray is a class in css-->
                                <!--<tr mat-row *matRowDef="let row; let even = even; columns: displayedColumns;" [ngClass]="{gray: even}"></tr>  striped deaktivieren-->
                                <tr mat-row *matRowDef="let row; let even = even; columns: displayedColumns;"></tr>

                            </table>

                        </div>

                    </mat-expansion-panel>

                    <br><br>

                </mat-accordion>
            </td>
        </tr>
    </table>
</div>