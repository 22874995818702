import {  CanActivate, Router } from "@angular/router";
import { AuthService } from "./auth.service";
import { Observable } from "rxjs/Observable";
import { Injectable } from "@angular/core";

/**
 * Der Service bietet die Möglichkeit die Route zu schutzen
 */

@Injectable({
    providedIn: "root"
  })
export class AuthGuard implements CanActivate {

  //Konstruktor
  constructor(private authService: AuthService, private router: Router) { }

  // aktiviert oder deaktiviert eine Route
  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    // falls der User eingeloggt ist 
    if (this.authService.isConnected) {     
      return true;
    }
    else {
      //zur Startseite weiterleiten
      this.router.navigate(["/"]);
    }

  }

}
