import { GenericmodaldisplayComponent } from "../GUI-components/modaldialogs/genericmodaldisplay/genericmodaldisplay.component";
import { NosignificantchangesComponent } from "../GUI-components/modaldialogs/nosignificantchanges/nosignificantchanges.component";
import { AppSettingsService } from "./appSetting.service";
import { MatDialog } from "@angular/material/dialog";
import { IMessage } from '../models/IMessage.model';
import { Injectable } from "@angular/core";

/**
 * Der service zeigt eine Nachricht an
 */

@Injectable({
  providedIn: "root"
})
export class GenericDisplaySservice {

  constructor(private dialog: MatDialog, private appSettingsService: AppSettingsService) { }

  /**
   * zeigt das Warning-Popup-Fenster an, wenn eine Datenänderung nicht signifikante war
   */
  showWarningPopup() {

    const dialogRef = this.dialog.open(NosignificantchangesComponent, {
      height: "275px",
      width: "550px",

    });

    /* auskommentiert damit der User selber schliessen kann
    setTimeout(() => {
      dialogRef.close();
    }, this.appSettingsService.getModalDisplayingtime() * 1000);
    */
  }

  /**
   * Warnungsanzeige
   * @param title, Titel der Warnung 
   * @param errorResponse, Fehler aus dem API 
   * @param screen, Nachricht-Kategorie Bestätigung | Fehler usw.
   */
  showAlert(title: string, errorResponse: any, screen?: string, isInfo?: number) {

    let message: string = errorResponse;

    // nach dem richtigen Fehler-Property suchen
    if (typeof (errorResponse) === 'string')
      message = errorResponse
    else {
      if (errorResponse && errorResponse.error && errorResponse.error.message)
        message = errorResponse.error.message;
      else if (errorResponse && errorResponse.message)
        message = errorResponse.message;
      else if (errorResponse && errorResponse.error)
        message = errorResponse.error;
      else if (errorResponse && errorResponse.statusText)
        message = errorResponse.statusText;
    }

    // JSON mit dem Fehler erzeugen
    let errorMessage: IMessage = {
      title: title,
      message: message,
      screen: screen,
      info: isInfo
    }

    // Anzeige des Modals mit der Nachricht
    this.dialog.open(GenericmodaldisplayComponent, {
      height: "310px",
      width: "550px",
      data: errorMessage
    });

  }
}