
import { IWebcatalogchangeobject } from '../models/IWebcatalogchangeobject.model';
import { Injectable } from "@angular/core";

/**
 *  Der Service bietet die Routinen an, die den Programmierer bei den Operationen auf den Strings unterstützt
 */

@Injectable({
    providedIn: "root"
})
export class StringFunctionsService {

    constructor() {
    }

    /**
     * gib an, ob ein String leer, undefined oder null ist
     * @param data 
     */
    public isUndefinedNullOrEmpty(data: string): boolean {

        return this.isUndefinedOrNull(data) ||
            (data !== null && data !== undefined && data.toString().trim().length === 0);
    }

   /**
    * gib an, ob zwei string unterschiedlich sind
    * @param group, Datengruppe 
    * @param target, der Bezug 
    * @param oldvalue, alter Wert 
    * @param newvalue, neuer Wert
    */
    public stringvaluesAreDifferent(group:string, target:string, oldvalue: string, newvalue: string): IWebcatalogchangeobject {

        return {
            "fieldchangesvalue": (!this.isUndefinedNullOrEmpty(oldvalue) && !this.isUndefinedNullOrEmpty(newvalue) && oldvalue !== newvalue),
            "fieldreceivednewvalue": (this.isUndefinedNullOrEmpty(oldvalue) && !this.isUndefinedNullOrEmpty(newvalue)),
            "fieldlostvalue": (!this.isUndefinedNullOrEmpty(oldvalue) && this.isUndefinedNullOrEmpty(newvalue)),
            "Datagroup":group,
            "Target":target
        };


    }

    /**
     * gib an, ob ein string null ist
     * @param data, das string
     */
    public isUndefinedOrNull(data: any): boolean {
        return (data === null || data === undefined);
    }

    /**
     * formatiert ein string und ersetzt die Platzhater
     * @param str, string das der Platzhalter enthält 
     * @param val, Wert mit dem der Platzhalter ersetzt werden soll 
     */
    public FormatString(str: string, val: string[]) {
        for (let index = 0; index < val.length; index++) {
            str = str.replace(`{${index}}`, val[index]);
        }
        return str;
    }

    /**
     * entfernt Leerzeichen im String
     * @param data string
     */
    public trimmed(data: string): string {
        if (!this.isUndefinedNullOrEmpty(data)) {
            return data.trim();
        } else if (!this.isUndefinedOrNull(data)) {
            return data.trim();
        }
        return data;
    }

    /**
     * formatiert eine Zahl und setzt ein 0 vor der Zahl
     * @param value, der Wert
     */
    private formatTimeParameter(value: number): string {

        if (value < 10)
            return this.FormatString("{0}" + value.toString(), ["0"]);
        return value.toString();
    }

    /**
     * liefert das Datum von heute zurück
     */
    public DateNowToString() {
        let now = new Date();


        return now.getFullYear() + "-" +
            this.formatTimeParameter((now.getMonth() + 1)) + "-" +
            this.formatTimeParameter(now.getDate()) + " " +
            this.formatTimeParameter(now.getHours()) + ":" +
            this.formatTimeParameter(now.getMinutes());
    }

    /**
     * liefert der letzte Tag des Monats
     * @param year, Jahr
     * @param month, Monat 
     */
    getLastDayOfAMonth(year: number, month: number) {
        return new Date(year, month + 1, 0).getDate();
    }

    /**
     * liefert der erste Tag des nächsten Monats zurück
     */
    public FirstDayOfNextMonthString() {
        let now = new Date();
        let lastday = this.getLastDayOfAMonth(now.getFullYear(), now.getMonth());
        let newDateWithLastday = new Date(now.getFullYear(), now.getMonth(), lastday)
        let firstdayOfMonth = new Date(newDateWithLastday.getTime() + (1 * 24 * 60 * 60 * 1000));
        return this.stringToDateshortFormat(firstdayOfMonth.toString());
    }

    /**
     * wandelt ein string als Datum um
     * @param value 
     */
    public stringToDateshortFormat(value: string): string {

        let date = new Date(value);

        return (date.getFullYear() + "-" +
            this.formatTimeParameter((date.getMonth() + 1)) + "-" +
            this.formatTimeParameter(date.getDate()))+"  ";
    }
}