import { Component, OnInit } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { MatDialogRef } from '@angular/material/dialog';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MobiletypesService } from 'src/app/services/mobiletypes.service';

/**
 * Komponent-Klasse zur Auswahl von mobiletypen von mobilen Angeboten
 */

@Component({
  selector: 'mobiletypeselector',
  templateUrl: './mobiletypeselector.component.html',
  styleUrls: ['./mobiletypeselector.component.css']
})
export class MobiletypeselectorComponent implements OnInit {

  mobiletypeform: UntypedFormGroup;
  mobiletypes$ = new BehaviorSubject<any[]>([]);
  mobiletype: string = "0";
  mobiles = [];
  constructor(private formBuilder: UntypedFormBuilder, private dialogRef: MatDialogRef<MobiletypeselectorComponent>,
    private mobiletypesService: MobiletypesService) { }

  ngOnInit() {

    this.mobiletypesService.readMobiletypes().subscribe(mobiletypes => { this.mobiletypes$.next(mobiletypes["data"].slice()); });

    this.mobiletypeform = this.formBuilder.group({
      mobiletypes: []
    })
  }
  onCancel() {
    this.dialogRef.close();
  }
  onSubmit() {
    this.mobiletypes$.subscribe(mobiles => {
      for (let obj of mobiles)
        if (obj.mobiletypeId == this.mobiletype) {

          /*
          var uint8array = new TextEncoder().encode(obj.description);

          var string = new TextDecoder().decode(uint8array);
          console.log(uint8array, string)
        */
          if (Number(obj.mobiletypeId) === 60)
            obj.description = "Zuzaehlung"
          this.dialogRef.close(this.mobiletype + ";" + obj.description);
        }
    });
  }

}
