import { userDataChangesdataCategorieIds } from "../enumerations/userDataChangesdataCategorieIds.enum";
import { userDataChangesState } from "../enumerations/userDataChangesState.enum";
import { ChangedFieldObject } from "../models/ChangedFieldObject.model";
import { StringFunctionsService } from "./stringFunctions.service";
import { UserDataChangesService } from "./userDataChanges.service";
import { UserDataChanges } from "../models/UserDataChanges.model";
import { Field } from "../models/Field.model";
import { Injectable } from "@angular/core";


/**
 * Der Service gleich die Datenänderungen der Locallisten
 */


@Injectable({
    providedIn: "root"
})
export class LocallistDatacomparatorService {
    
    constructor(private stringService: StringFunctionsService, private userChangeService: UserDataChangesService) { }

    
    /**
     * Datenabgleich der Locals 
     * @param principalDomain, Mitgliedskürzel
     * @param offername,  Angebotsname
     * @param offerRowId, Zeile-Id des Angebotes in der Tabelle offers 
     * @param offerLocallists, Localliste des Angebotes 
     * @param newLocaldata, der neue Local, der hinzugefügt werden soll 
     */
    compareLocalitemToInsert(principalDomain: string, offername: string, offerRowId:number, offerLocallists: string[], newLocaldata: string): UserDataChanges {

        offername = this.stringService.trimmed(offername);
        newLocaldata = this.stringService.trimmed(newLocaldata);

        if (offername.length > 0 && newLocaldata.length > 0) {

            if (offerLocallists.indexOf(newLocaldata) < 0) {

                //Änderungsobjekt erstellen
                return new UserDataChanges(principalDomain, 
                    userDataChangesdataCategorieIds.localchange, 
                    this.stringService.DateNowToString(), 
                    "locallists",
                     userDataChangesState.pending, 
                     [new Field("Local", "local", "", newLocaldata)], 
                     null, offername, offerRowId);
            }
        }
        return null;
    }

    /**
     * Datenabgleich der Locals 
     * @param principalDomain, Mitgliedskürzel
     * @param offername,  Angebotsname     
     * @param offerLocallists, Localliste des Angebotes 
     * @param localToDelete, der Local, der gelöscht werden soll 
     */
    compareLocalitemToDelete(principalDomain: string, offername: string, offerLocallists: string[], localToDelete: string): UserDataChanges {
        // Leerzeichen vor und nach dem Eintrag entfernen
        offername = this.stringService.trimmed(offername);
        localToDelete = this.stringService.trimmed(localToDelete);

        if (offername.length > 0 && localToDelete.length > 0) {

            if (offerLocallists.indexOf(localToDelete) >= 0) {

                //Änderungsobjekt erstellen
                return new UserDataChanges(principalDomain, 
                    userDataChangesdataCategorieIds.localchange, 
                    this.stringService.DateNowToString(), 
                    "locallists",
                     userDataChangesState.pending, 
                     [new Field("Local", "local", localToDelete, "")], 
                     null, offername);
            }
                    
        }
        return null;
    }
    
    /**
     * 
     * @param userDataChangesId, Id der Datenänderung 
     * @param principalDomain, Mitgliedskürzel 
     * @param unconfirmedLocallists, unbestätiger Local 
     * @param offername, Angebotsname 
     * @param localToDelete, zu löschender Local 
     */
    deletePendinglocalchange(userDataChangesId:string, principalDomain:string, unconfirmedLocallists :string[], offername:string, localToDelete:string){
         if (unconfirmedLocallists.indexOf(localToDelete) >= 0) {
            // Service-Aufruf
            this.userChangeService.refuseChange(new ChangedFieldObject(userDataChangesId, principalDomain, offername, userDataChangesdataCategorieIds.localchange, 
                "Local", this.stringService.DateNowToString(),null, localToDelete,"locallists","local",  
                userDataChangesState.pending, null, null,null,null)).subscribe((response)=>{
                    //console.log(response);
                });
            
         }
    }
}