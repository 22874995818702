 <h1 mat-dialog-title mat-dialog-draggable-title class="text-primary" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>{{data.headline}}
    <button type="button" class="close" (click)="cancelOption()" data-dismiss="modal" aria-hidden="true">×</button>
</h1>
<!-- class="modal-dialog" verursacht ein schmalles Modal-->
<!-- <div class="modal-dialog" style="display:table;"> -->
    <div style="display:table;">
    <main>
        <br>
        <p align="right"><b>hinterlegt am {{data.insertdate}}</b>
        </p>
        <br><br>

          <div [innerHTML]="extractNews()"></div> 
       
    </main>
   
</div>


