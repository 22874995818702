import { ComponentCanDeactivate } from "./component-can-deactivate";
import { TokenService } from 'src/app/services/token.service';
import { CanDeactivate } from "@angular/router";
import { Injectable } from "@angular/core";

@Injectable()
export class CanDeactivateGuard implements CanDeactivate<ComponentCanDeactivate> {

  constructor(private token: TokenService) { }

  /**
   * handelt, ob der Formularausfüllungsabbruch mit nicht gespeicherten Daten
   * @param component 
   */
  canDeactivate(component: ComponentCanDeactivate): boolean {

    if (!component.canDeactivate()) {

      // Falls der User auf singout angeklickt hat muss er SOFORT raus, da das Token nicht mehr gültig ist
      if (!this.token.jwtokenExists())
        return true;
      
      if (confirm("Achtung!!!! Nicht gespeicherte Änderungen gehen verloren, wenn der Bildschirm verlassen wird.")) {
        return true;
      } else {

        return false;
      }
    }
    return true;
  }
}
