
import { environment } from 'src/environments/environment';
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

/**
 * Der Service spricht das API an, und liefert Daten-Kategorien zurück
 */



@Injectable({
    providedIn: "root"
})
export class DataCategoriesService {

    private apiurl = environment.apiRootDirectory;

    constructor(private _http: HttpClient) { }

    // lies die Daten-Kategorien
    readDataCategories() {

        return this._http.get<any>(this.apiurl + "data/dataCategories/readDataCategories.php");
    }
}