import { environment } from "src/environments/environment";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs/Observable";
import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

/**
 * lies die core.php und stellt die vorhanden Konfigurationsdaten zur Verfügung
 */

@Injectable({
    providedIn: "root"
})
export class ApiCoreService {
    private apiurl = environment.apiRootDirectory;

    constructor(private http: HttpClient) {

    }

    //lies die core.php und stellt die vorhanden Konfigurationsdaten zur Verfügung
    public readCore(): Observable<any> {
        return this.http.get<any>(this.apiurl + "data/common/readCore.php");
    }

    /*
    async apiCore() {
    
        let dataApiRegex = new BehaviorSubject<any>(null);

        dataApiRegex = await this.readCore().toPromise()
        return dataApiRegex;
       
      //neue Schreibweise von subscribe
      // this.readCore().subscribe({
      // next: (coredata: any) => {
      //     dataApiRegex.next(coredata);                     
      //  },
      //  complete: () => { },
      //  error: () => { }
      //  });


    }
  */

}