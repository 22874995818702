import { userDataChangesdataCategorieIds } from "../enumerations/userDataChangesdataCategorieIds.enum";
import { environment } from 'src/environments/environment';
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

/**
 * Der Service verschickt die E-Mails
 */


@Injectable()
export class EmailService {

  private apiurl = environment.apiRootDirectory;

  constructor(private _http: HttpClient) { }

  /**
   * Benachrichtigung über die Datenänderungen
   * @param principalDomain , Mitgliedskürzel
   * @param customername, Kundenname 
   * @param changes, Änderungen 
   * @param dateReference, Bezugsdatum wann die Änderungen stattgefunden haben
   * @param from, Absender
   * @param to, Receiver 
   * @param cc, Copy carbon 
   * @param userDataChangesdataCategorie, Datengruppe
   */
  sendMailAboutChanges(principalDomain: string, customername: string, changes: string, dateReference: string, from: string, to: string, cc: string, userDataChangesdataCategorie: userDataChangesdataCategorieIds){
    return this._http.post<any>(this.apiurl + "mails/sendMail.php", {
      "principalDomain": principalDomain,
      "from": from,
      "to": to,
      "cc": cc,
     
      "customername": customername,
      "changes": changes,
      "dateReference": dateReference,
      "dataCategorieId": userDataChangesdataCategorie

    });

  }

  /**
   * Benachrichtigung über die Datenänderungen der Kundendaten
   * @param principalDomain , Mitgliedskürzel
   * @param customername, Kundenname 
   * @param changes, Änderungen 
   * @param dateReference, Bezugsdatum wann die Änderungen stattgefunden haben
   * @param from, Absender
   * @param to, Receiver 
   * @param cc, Copy carbon 
   * 
   */
  sendMailAboutCutomerdatachanges(principalDomain: string, customername: string, changes: string, dateReference: string, from: string, to: string, cc: string) {
   return this.sendMailAboutChanges(principalDomain, customername, changes, dateReference, from, to, cc, userDataChangesdataCategorieIds.compagnydata);

  }

  /**
   * Benachrichtigung über die Datenänderungen der Locals
   * @param principalDomain , Mitgliedskürzel
   * @param customername, Kundenname 
   * @param changes, Änderungen 
   * @param dateReference, Bezugsdatum wann die Änderungen stattgefunden haben
   * @param from, Absender
   * @param to, Receiver 
   * @param cc, Copy carbon 
   * 
   */
  sendMailAboutLocallistchanges(principalDomain: string, customername: string, changes: string, dateReference: string, from: string, to: string, cc: string) {
    return this.sendMailAboutChanges(principalDomain, customername, changes, dateReference, from, to, cc, userDataChangesdataCategorieIds.localchange);
   
  }

  /**
   * Benachrichtigung über die Datenänderungen der Reportübermittlung
   * @param principalDomain , Mitgliedskürzel
   * @param customername, Kundenname 
   * @param changes, Änderungen 
   * @param dateReference, Bezugsdatum wann die Änderungen stattgefunden haben
   * @param from, Absender
   * @param to, Receiver 
   * @param cc, Copy carbon 
   * 
   */
  sendMailAboutChangesOnReportTransmission(principalDomain: string, offername: string, changes: string, dateReference: string, from: string, to: string, cc: string){
    return this._http.post<any>(this.apiurl + "mails/sendMailToCustomerService.php", {
      "principalDomain": principalDomain,
      "from": from,
      "to": to,
      "cc": cc,     
      "offername": offername,
      "changes": changes,
      "dateReference": dateReference   

    });

  }

  /**
   * Benachrichtigung über die Datenänderungen der Angebotsdaten
   * @param principalDomain , Mitgliedskürzel
   * @param customername, Kundenname 
   * @param changes, Änderungen 
   * @param dateReference, Bezugsdatum wann die Änderungen stattgefunden haben
   * @param from, Absender
   * @param to, Receiver 
   * @param cc, Copy carbon 
   * 
   */
  sendMailAboutOfferdatachanges(principalDomain: string, offername: string, changes: string, dateReference: string, from: string, to: string, cc: string) {

    return this.sendMailAboutChanges(principalDomain, offername, changes, dateReference, from, to, cc, userDataChangesdataCategorieIds.offerdata);

   
  }
}