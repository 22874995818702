import { environment } from 'src/environments/environment';
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

/**
 * Der Service spricht der API um User-Logins an
 */

@Injectable({
  providedIn: "root"
})
export class UserService {

  private apiurl = environment.apiRootDirectory;


  constructor(private _http: HttpClient) { }

  // lies die aktiven Logins
  readActiveAdminLogins() {
    return this._http.get<any[]>(this.apiurl + "data/user/readactiveAdminLogins.php");

  }

    // lies die aktiven Logins
    readDeacactivedAdminLogins() {
      return this._http.get<any[]>(this.apiurl + "data/user/readDeactivedAdminLogins.php");
  
    }
}
