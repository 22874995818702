import { environment } from "src/environments/environment";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

/**
 * Der Service spricht der API an, um die Webkatalog-Daten
 */

@Injectable({
  providedIn: "root"
})
export class WebcatalogService {

  private apiurl = environment.apiRootDirectory;


  constructor(private _http: HttpClient) { }

  /**
   * selektiert die Webkatalog-Daten eines Angebotes
   * @param siteinfoId Id des Mitglieds
   */
  selectWebcatalog(siteinfoId: string) {
    if (siteinfoId !== null && siteinfoId !== undefined) {
      return this._http.post<any>(this.apiurl + "data/webcatalog/readWebcatalog.php", { "siteinfoId": siteinfoId });
    }
  }


  /**
   *  löscht das Logo
   * @param siteinfoId, Id des Mitglieds 
   * @param principalDomain, Mitgliedskürzel 
   * @param providername, Name des Anbieters 
   */
  deleteLogo(siteinfoId: string, principalDomain:string, providername:string ) {

      return this._http.post<any>(this.apiurl + "data/webcatalog/deleteLogoOfByMember.php", { "siteinfoId": siteinfoId, "providername":providername, "principalDomain":principalDomain });
  }

  /**
   * inseriert oder aktualisiert der Webcatalog
   * @param siteinfoIdId, Id des Mitglieds 
   * @param data Data  des Mitglieds 
   */
  insertOrUpdateWebcatalog(siteinfoId: string, data: any) {

    if (data !== null && data !== undefined) {
      return this._http.post<any>(this.apiurl + "data/webcatalog/saveWebcatalog.php", { "siteinfoId": siteinfoId, "webcatalog": JSON.stringify(data.formvalues), "changesregistered": JSON.stringify(data.changesregistered) });
    }
  }

  /**
   * selektiert die Webcataloge des Mitglieds
   * @param principalDomain, Mitgliedskürzel
   */
  selectWebcatalogByMember(principalDomain: string) {

    if (principalDomain !== null && principalDomain !== undefined) {
      return this._http.post<any>(this.apiurl + "data/webcatalog/readWebcatalogByMember.php", { "principalDomain": principalDomain });
    }
  }



}