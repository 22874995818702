import { PasswordchangeconfirmationComponent } from "../modaldialogs/passwordchangeconfirmation/passwordchangeconfirmation.component";
import { AppSettingsService } from "src/app/services/appSetting.service";
import { UntypedFormGroup, UntypedFormBuilder, Validators } from "@angular/forms";
import { MatDialogRef, MatDialog } from "@angular/material/dialog";
import { AuthService } from "src/app/services/auth.service";
import outputmessage from "src/assets/messagetokens.json";
import { IMessage } from 'src/app/models/IMessage.model';
import { Component, OnInit } from "@angular/core";


/**
 * Komponent-Klasse der Passwortfunktion
 */

@Component({
  selector: "accountrecovery",
  templateUrl: "./accountrecovery.component.html",
  styleUrls: ["./accountrecovery.component.css"]
})
export class AccountrecoveryComponent implements OnInit {
  // Frontendseitiges Display von Fehlern 
  errorMessage: string;
  // Formular Objekt
  accountRecoveryForm: UntypedFormGroup;
  constructor(private modaldialog: MatDialogRef<AccountrecoveryComponent>,
    private authService: AuthService,
    private formBuilder: UntypedFormBuilder,
    private dialog: MatDialog,
    private appSettingsService: AppSettingsService) { }

  ngOnInit() {

    /* Initilialiserung des Formulars */
    this.accountRecoveryForm = this.formBuilder.group({
      username: ["", [Validators.required]]
      
    });

  }

  /**
   * Passwort-Anfrage bearbeiten
   */
  onSubmit() {
    // Lesen der Eingabe zur Passwort-Anfrage
    const username = this.accountRecoveryForm.get("username").value;
    //const email = this.accountRecoveryForm.get("email").value;
    //Aufruf des Services
    this.authService.askPassword(username).subscribe((askPasswordResponse) => {
      
      //Falls Die Anfrage erfolgreich war
      if (askPasswordResponse && askPasswordResponse.success && askPasswordResponse.success === true) {
        this.cancelOption();
        // Modal zur Bestätigung zeigen
        this.showModalDialog(true, askPasswordResponse.recipient);

      }//Falls Die Anfrage nicht erfolgreich war
      else {
        this.showModalDialog(false);
      }
    }, (askPasswordError) => {
      
      // Falls die API-Funktion einen Fehler zurückliefert
      if (askPasswordError)
        // Falls ein Message-Feld vorliegt
        if (askPasswordError.error && askPasswordError.error.error) { this.errorMessage = askPasswordError.error.error; }
        // Falls ein Error-Feld vorliegt
        else if (askPasswordError.message) { this.errorMessage = askPasswordError.message; }
        // Sonst
        else { this.errorMessage = askPasswordError; }
    }
    );


  }
  /**
   * Abbruch der Passwort-Anfrage
   */
  cancelOption() {
    // Modal schliessen
    this.modaldialog.close();

  }

  /**
   * Modal-Display je nach dem der Prozess erfolgreich war oder nicht
   * @param operationWasSuccessfull 
   */
  showModalDialog(operationWasSuccessfull: boolean, recipient?:string) {
    let imessage: IMessage;

    // Falls der Prozess erfolgreich ist
    if (operationWasSuccessfull) {
      imessage = {
        title: outputmessage.headerconfirmationmodal,
        message: outputmessage.askedpasswordSent+"( an: "+recipient+")",
        screen: "confirmation",        
        info: 0
      }
    }
    // Falls der Prozess NICHT erfolgreich ist 
    else {
      imessage = {
        title: outputmessage.headerwarning,
        message: outputmessage.accountRecoveryWarning,
        screen: "warning",
        info: 0
      }
    }

    // Display des Modals mit automatischer Schliessung des Fenrsters nach vordefinierter Dauer
    const dialogRef = this.dialog.open(PasswordchangeconfirmationComponent, {

      height: "280px",
      width: "550px",
      data: imessage
    });

    setTimeout(() => {
      dialogRef.close();
    }, this.appSettingsService.getModalDisplayingtime() * 1000);
  }


}
