<!-- Logo-->
<nav class="navbar navbar-light" style="background-color: #cccc99;">
  <table width="100%">
    <tr>
      <td width="30%" align="left"><img src="../assets/oewalogotext.gif" alt="O&uml;WA" /></td>
      <td width="30%">
      </td>
      <td width="35%">
      </td>
      <td width="5%" align="center">
        <table>
          <tr>
            <td align="center">
              <p class="navbar-brand" style="color:#0B0B61; font-weight: bold;">Ö W A Member Interface</p>
            </td>
          </tr>
          <tr>
            <td class="useraccount">{{usertooltip$ | async}}</td>
          </tr>
        </table>
      </td>
    </tr>
  </table>
</nav>
<!-- Login Maske-->
<div [hidden]="isLogged$ | async" class="signindiv">
  <div class="signindivform">
    <signin (connectedAccount)="getIMemberAccount($event)"></signin>
  </div>
</div>

<!-- Navigation Bar-->
<nav class="navbar navbar-expand-lg  navbar-default" *ngIf="isLogged$ | async">
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
    aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <ul class="navbar-nav mr-auto" style="list-style: none;">

      <li routerLinkActive="active" class="nav-item" *ngIf="isLogged$ | async">
        <button id="menuButton" routerLink="customerdata" type="button" class="btn btn-secondary btn-sm">
          Allgemeine Informationen
        </button>
      </li>

      <li routerLinkActive="active" class="nav-item" *ngIf="isLogged$ | async">
        <button id="menuButton" type="button" class="btn btn-secondary btn-sm"
          (click)="showOfferList()">Angebote</button>
      </li>


      <li class="nav-item dropdown" *ngIf="isLogged$ | async">
        <span class="nav-link dropdown-toggle btn btn-secondary menuButton" id="navbarDropdown" role="button"
          data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          Services
        </span>
        <div class="dropdown-menu" aria-labelledby="navbarDropdown">
          <a class="dropdown-item" routerLink="membernews">Meldung(en)</a>

          <!-- auskommentiert.  Ausblenden-Wunsch von ÖWA
          <div class="dropdown-divider"></div>
          <a class="dropdown-item" routerLink="agreementpreview">Vertrag zur Auftragsverarbeitung
          </a>
          -->

          <div class="dropdown-divider"></div>
          <a class="dropdown-item" (click)="changesRegistrationDownloading()">Änderungsantrag
          </a>
        </div>

      </li>

      <!-- Ausgeblendet am 17.10.2022 nach ÖWA-Wunsch
      <li routerLinkActive="active" class="nav-item" *ngIf="((isLogged$ | async) && (isAdvertisingprovider$ | async))">
        <button id="menuButton" type="button" routerLink="webcatalog"
          class="btn btn-secondary btn-sm">Webkatalog</button>
      </li>
    -->

      <li class="nav-item dropdown" *ngIf="isAdmin$ | async">
        <span class="nav-link dropdown-toggle btn btn-secondary menuButton" id="navbarDropdown" role="button"
          data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          Admin
        </span>
        <div class="dropdown-menu" aria-labelledby="navbarDropdown">

          <a class="dropdown-item" routerLink="datachangeprocessing">Änderung(en)</a>

          <div class="dropdown-divider"></div>
          <a class="dropdown-item" (click)="goToAdmininputData()">Datenpflege
          </a>
        </div>
      </li>

    </ul>

    <!-- Hilfe, Kontakt und Anmelden-->
    <div class="btn-group">
      <ul class="navbar-nav mr-auto" style="list-style: none;">

        <li routerLinkActive="active" class="nav-item" *ngIf="isLogged$ | async">
          <button id="menuButton" type="button" class="btn btn-secondary btn-sm" title="Hilfe" routerLink="help"><i
              class="fa fa-question-circle fa-2x" aria-hidden="true"></i>
          </button>
        </li>
        <li routerLinkActive="active" class="nav-item" *ngIf="isLogged$ | async">
          <button id="menuButton" type="button" class="btn btn-secondary btn-sm" title="schreiben Sie uns"
            (click)="sendEmail()"><i class="fa fa-envelope fa-2x" aria-hidden="true"></i>
          </button>
        </li>

        <li routerLinkActive="active" class="nav-item" [hidden]="isLogged$ | async">
          <span class="nav-link" id="menustyle" routerLink="signin">Login</span>
        </li>

        <li routerLinkActive="active" class="nav-item" *ngIf="isLogged$ | async">
          <button id="menuButton" type="button" class="btn btn-secondary btn-sm" title="Logout" style="color:#dc3545"
            (click)="signOut()"><i class="fa fa-sign-out fa-2x" aria-hidden="true"></i> </button>
        </li>


      </ul>
    </div>

    <!-- sessioncountdown  tokenService.getRemainingTime() -->
    <sessioncountdown *ngIf="isLogged$ | async" [init]="tokenService.getRemainingTime()"></sessioncountdown>

    <!-- Dropdown mit den Lizenznehmer-->
    <div class="form-inline my-2 my-lg-0" *ngIf="(showMarketerDropdown | async)">


      <form [formGroup]="marketerGroupForm">
        <select formControlName="marketerGroupControl" class="custom-select mr-sm-2" [(ngModel)]="licencereceiver"
          (change)="onSelectionOfOfferFromMarketerGroupChanged()">
          <option [value]="licencereceiver" *ngFor="let licencereceiver of marketerGroupForm$ | async">
            {{licencereceiver}}</option>

        </select>

      </form>
    </div>
    <!-- User-Account-->
    <div class="btn-group">
      <button type="button" class="oewaGold btn no-click">{{connectedUser$ | async}}</button>&nbsp;
      <button type="button" class="oewaGold dropdown-toggle dropdown-toggle-split" id="dropdownMenuReference"
        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-reference="parent">
        <span class="sr-only">Toggle Dropdown</span>
      </button>
      <div class="dropdown-menu" style="right: 0; left: auto;" aria-labelledby="dropdownMenuReference">
        <a class="dropdown-item" routerLink="passwordchange">Passwort ändern</a>

      </div>
    </div>&nbsp;
    <!-- Dropdown mit den Kürzeln sichtbar nur bei Vermarkter oder Admin-->
    <div class="form-inline my-2 my-lg-0"
      *ngIf="((connectedAccountIsAdmin | async) || (connectedAccountIsMarketer | async))">
      <form [formGroup]="sitesForm">

        <select formControlName="domainsControl" class="custom-select mr-sm-2" [(ngModel)]="principaldomain"
          (change)="onSelectionOfPrincipalChanged()">

          <option [value]="domain" *ngFor="let domain of domains$ | async"> {{domain}}</option>

        </select>

      </form>
    </div>


  </div>
</nav>