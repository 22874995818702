
/**
 * Modelklasse des Datenänderungsobjekts
 */
export class ChangedFieldObject {

    constructor(
        public id: string,
        public principalDomain: string,
        public offername: string,
        public dataCategorieId: string,
        public changedFieldDesc: string,
        public changeDate: string,
        public oldvalue: string,
        public newvalue: string,
        public tablename: string,
        public fieldname: string,
        public processed: string,
        public fieldidentity: string,
        public fieldidentValue: string,
        public target: string,
        public offerRowId: string
    ) { }
   

}

