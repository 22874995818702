import { userDataChangesdataCategorieIds } from "../enumerations/userDataChangesdataCategorieIds.enum";
import { IOfferEditControlsIdValuePair } from '../models/IOfferEditControlsIdValuePair.model';
import { userDataChangesState } from "../enumerations/userDataChangesState.enum";
import { StringFunctionsService } from "./stringFunctions.service";
import { UserDataChangesService } from "./userDataChanges.service";
import { UserDataChanges } from "../models/UserDataChanges.model";
import { contactTypes } from "../enumerations/contactTypes.enum";
import { DataComparatorService } from "./dataComparator.service";
import { SqlQueryService } from "./sqlQuery.service";
import { CryptoService } from './crypto.service';
import { Field } from "../models/Field.model";
import { Injectable } from "@angular/core";



/**
 * Der Service gleicht die Angebotsdaten ab
 */


@Injectable({
    providedIn: "root"
})
export class OfferdataComparatorService {
    // Änderungen die kontakt ambulant sind
    resultChangedOfferfieldsRelatedToContact: Field[] = [];
    // Änderungen die nicht kontakt ambulant sind
    resultOtherFieldsChangeds: Field[] = [];

    constructor(private stringService: StringFunctionsService,
        private dataComparatorService: DataComparatorService,
        private userChangeService: UserDataChangesService,
        private sqlQueryService: SqlQueryService,
        private cryptoservice: CryptoService) { }

    /**
     * fügt im Container hinzu
     * @param array inseriert 
     * @param field 
     */
    public addIn(container: Field[], field: Field) {

        
        if (field !== null) {
            container.push(field);
        }
      
    }


    /**
     * gleicht die Angebotsdaten ab
     * @param pendingchangesValues, noch nicht bestätigte Änderungen 
     * @param pendingchangesControldIdValuePair, Mapping Controls und  noch nicht bestätigte werte 
     * @param principalDomain, Mitgliedskürzel 
     * @param isMobilOffer, gib an, ob das Angebot mobil ist 
     * @param oldofferdata, alte Angebotsdaten 
     * @param newofferdata, neue Angebotsdaten 
     */
    compareofferdata(pendingchangesValues: any, pendingchangesControldIdValuePair: IOfferEditControlsIdValuePair, principalDomain: string, isMobilOffer: boolean, oldofferdata: any, newofferdata: any) {

        this.resultOtherFieldsChangeds = [];
        this.resultChangedOfferfieldsRelatedToContact = [];

        // objekt initialisieren um Nullreferenz- error zu vermeiden
        if (oldofferdata === undefined || oldofferdata === null)
            oldofferdata = {}

        // Daten pro Felds abgleichen
        this.addIn(this.resultChangedOfferfieldsRelatedToContact, this.dataComparatorService.compareContactFieldValues(oldofferdata.reportingofficerFirstname, newofferdata.reportingofficerFirstname, pendingchangesControldIdValuePair.reportingofficerFirstname, contactTypes.reportOfficer, "firstname", "Vorname"));

        this.addIn(this.resultChangedOfferfieldsRelatedToContact, this.dataComparatorService.compareContactFieldValues(oldofferdata.reportingofficerLastname, newofferdata.reportingofficerLastname, pendingchangesControldIdValuePair.reportingofficerLastname, contactTypes.reportOfficer, "lastname", "Nachname"));

        this.addIn(this.resultChangedOfferfieldsRelatedToContact, this.dataComparatorService.compareContactFieldValues(oldofferdata.reportingofficerEmail, newofferdata.reportingofficerEmail, pendingchangesControldIdValuePair.reportingofficerEmail, contactTypes.reportOfficer, "email", "E-Mail"));

        this.addIn(this.resultChangedOfferfieldsRelatedToContact, this.dataComparatorService.compareContactFieldValues(oldofferdata.reportingofficerPhone, newofferdata.reportingofficerPhone, pendingchangesControldIdValuePair.reportingofficerPhone, contactTypes.reportOfficer, "phone", "Telefon"));

        this.addIn(this.resultChangedOfferfieldsRelatedToContact, this.dataComparatorService.compareContactFieldValues(oldofferdata.reportingreceiverEmailOne, newofferdata.reportingreceiverEmailOne, pendingchangesControldIdValuePair.reportingreceiverEmailOne, contactTypes.firstReportRecipient, "email", "E-Mail"));

        this.addIn(this.resultChangedOfferfieldsRelatedToContact, this.dataComparatorService.compareContactFieldValues(oldofferdata.reportingreceiverEmailTwo, newofferdata.reportingreceiverEmailTwo, pendingchangesControldIdValuePair.reportingreceiverEmailTwo, contactTypes.secondReportRecipient, "email", "E-Mail"));

        this.addIn(this.resultChangedOfferfieldsRelatedToContact, this.dataComparatorService.compareContactFieldValues(oldofferdata.reportingreceiverEmailThree, newofferdata.reportingreceiverEmailThree, pendingchangesControldIdValuePair.reportingreceiverEmailThree, contactTypes.thirdReportRecipient, "email", "E-Mail"));


        //this.addIn(this.resultOtherFieldsChangeds, this.dataComparatorService.compareFieldValues(oldofferdata.offername, newofferdata.offername, pendingchangesControldIdValuePair.offername, "offername", "Angebotsname"));
        // offername ist nicht mehr der Angebotsname sondern eine Kennung
        // websitename als Angebotsname verwenden
        // ebenso websitename als FieldnameInTable verwenden

        this.addIn(this.resultOtherFieldsChangeds, this.dataComparatorService.compareFieldValues(oldofferdata.websitename, newofferdata.offername, pendingchangesControldIdValuePair.offername, "websitename", "Angebotsname"));

        this.addIn(this.resultOtherFieldsChangeds, this.dataComparatorService.compareFieldValues(oldofferdata.url, newofferdata.url, pendingchangesControldIdValuePair.url, "url", "URL"));

        this.addIn(this.resultOtherFieldsChangeds, this.dataComparatorService.compareFieldValues(oldofferdata.pricelist, newofferdata.pricelist, pendingchangesControldIdValuePair.pricelist, "pricelist", "Preisliste"));

        // zusätzlicher Abgleich für NICHT mobiles Angebot
        if (!isMobilOffer) {

            let ftpold = null;
            let scpold = null

            let ftpnew = null;
            let scpnew = null;
            if (Number(oldofferdata.ftpScp) === 2) {
                scpold = "1";
                ftpold = "0"
            }
            else {
                ftpold = "1"
                scpold = "0";
            }
            if (Number(newofferdata.ftpScp) === 2) {
                ftpnew = 0;
                scpnew = "1";
            }
            else {
                ftpnew = "1";
                scpnew = "0";
            }
            if (this.stringService.isUndefinedNullOrEmpty(pendingchangesControldIdValuePair.ftpScp) && Number(newofferdata.ftpScp) !== Number(oldofferdata.ftpScp)) {

                if (newofferdata.ftpScp === 2) {
                    this.addIn(this.resultOtherFieldsChangeds, this.dataComparatorService.compareFieldValues(ftpold, ftpnew, pendingchangesControldIdValuePair.ftpScp, "ftp", "FTP"));
                    this.addIn(this.resultOtherFieldsChangeds, this.dataComparatorService.compareFieldValues(scpold, scpnew, pendingchangesControldIdValuePair.ftpScp, "scp", "SCP"));
                }
                else {
                    this.addIn(this.resultOtherFieldsChangeds, this.dataComparatorService.compareFieldValues(ftpold, ftpnew, pendingchangesControldIdValuePair.ftpScp, "ftp", "FTP"));
                    this.addIn(this.resultOtherFieldsChangeds, this.dataComparatorService.compareFieldValues(scpold, scpnew, pendingchangesControldIdValuePair.ftpScp, "scp", "SCP"));
                }
            }

            else {

                if (!this.stringService.isUndefinedNullOrEmpty(pendingchangesControldIdValuePair.ftpScp) && Number(newofferdata.ftpScp) === Number(oldofferdata.ftpScp)) {


                    // Änderung ignorieren
                    this.userChangeService.TryRollbackChange(pendingchangesValues, userDataChangesdataCategorieIds.offerdata,
                        "offers",
                        oldofferdata.principalDomain,
                        oldofferdata.offername,
                        new Field("FTP", "ftp", ftpold, ftpnew), null);
                        //new Field("FTP", "ftp", ftpold, ftpnew), ["/offeredit", oldofferdata.principalDomain, oldofferdata.offername]);
                        // routing rausnehmen das wirkt sofort obwohl der User NICHT auf speicher geklickt hat


                    this.userChangeService.TryRollbackChange(pendingchangesValues, userDataChangesdataCategorieIds.offerdata,
                        "offers",
                        oldofferdata.principalDomain,
                        oldofferdata.offername,
                        new Field("SCP", "scp", scpold, scpnew),
                        null);
                        //["/offeredit", oldofferdata.principalDomain, oldofferdata.offername]);
                        // routing rausnehmen das wirkt sofort obwohl der User NICHT auf speicher geklickt hat


                }
            }

            // Sonderfall für Reportübermittlung
            // eine Änderung der Reportübermittlung erfordert einen Änderungsantrag
            //  hier wird dem user die Möglichkeit geben, eine Änderung die der Reportübermittlung zurückzusetzen
            if (oldofferdata.reportingLevelId === newofferdata.reportingLevelId) {
                this.specialcaseReportLevelId(pendingchangesValues, principalDomain, oldofferdata.offername, oldofferdata.reportingLevelId, newofferdata.reportingLevelId, pendingchangesControldIdValuePair.reportingLevelId, "reportingLevelId", "laufende Reportübermittlung")

            } else {
                this.addIn(this.resultOtherFieldsChangeds, this.dataComparatorService.compareFieldValues(oldofferdata.reportingLevelId, newofferdata.reportingLevelId, pendingchangesControldIdValuePair.reportingLevelId, "reportingLevelId", "laufende Reportübermittlung"));
            }
            this.addIn(this.resultOtherFieldsChangeds, this.dataComparatorService.compareFieldValues(oldofferdata.ftp_server, newofferdata.ftpserver, pendingchangesControldIdValuePair.ftpserver, "ftp_server", "FTP-Server"));

            this.addIn(this.resultOtherFieldsChangeds, this.dataComparatorService.compareFieldValues(oldofferdata.ftp_port, newofferdata.ftpport, pendingchangesControldIdValuePair.ftpport, "ftp_port", "FTP-Port"));

            this.addIn(this.resultOtherFieldsChangeds, this.dataComparatorService.compareFieldValues(oldofferdata.ftp_directory, newofferdata.ftpdirectory, pendingchangesControldIdValuePair.ftpdirectory, "ftp_directory", "FTP-Verzeichnis"));

            this.addIn(this.resultOtherFieldsChangeds, this.dataComparatorService.compareFieldValues(oldofferdata.ftp_user, newofferdata.ftpuser, pendingchangesControldIdValuePair.ftpuser, "ftp_user", "FTP-User"));

            // FTP-Passwörter entschlüsseln
            let oldofferdata_ftp_password_decrypted = this.cryptoservice.decryptData(this.stringService.trimmed(oldofferdata.ftp_password))
            let newofferdata_ftppassword_decrypted = this.cryptoservice.decryptData(this.stringService.trimmed(newofferdata.ftppassword))
            let pendingchangesftppassword_decrypted = this.cryptoservice.decryptData(this.stringService.trimmed(pendingchangesControldIdValuePair.ftppassword))

            //console.log(oldofferdata_ftp_password_decrypted)
            //console.log(newofferdata_ftppassword_decrypted)

            this.addIn(this.resultOtherFieldsChangeds, this.dataComparatorService.compareFieldValues(oldofferdata_ftp_password_decrypted, newofferdata_ftppassword_decrypted, pendingchangesftppassword_decrypted, "ftp_password", "FTP-Password"));
        }


        let dataChangesResult: UserDataChanges[] = [];
        const offertablename: string = "offers";
        const contacttablename: string = "contacts";
        const contacttableFieldidentity: string = "contacttypeId"

        /* Änderungen speichern */
        // hier muss oldofferdata.offername verwendet werden, da es eine Kennung und Teil der Schlüssel in der Tabelle offers ist
        // newofferdata.offername wäre falsch gewesen, das newofferdata.offername aus Frontend kommt und enthält den Angebotsname, der der Kunden kennt
        // liegen vor, die Änderungen die nicht kontakt ambulant sind
        if (this.resultOtherFieldsChangeds.length > 0) {
          
            //Änderungen speichern            
            dataChangesResult.push(new UserDataChanges(principalDomain, 
                userDataChangesdataCategorieIds.offerdata, 
                this.stringService.DateNowToString(), 
                offertablename, 
                userDataChangesState.pending, 
                this.resultOtherFieldsChangeds, 
                null,
                 oldofferdata.offername, oldofferdata.rowId));                                                                                 
               
                }               
                    

        // liegen vor, die Änderungen die kontakt ambulant sind
        if (this.resultChangedOfferfieldsRelatedToContact.length > 0) {
            //"offername": "", bleibt leer da wir bei Allgemeine Infos, kein Angebotsdaten verwalten
            dataChangesResult.push(new UserDataChanges(principalDomain, userDataChangesdataCategorieIds.offerdata, this.stringService.DateNowToString(), contacttablename, userDataChangesState.pending, this.resultChangedOfferfieldsRelatedToContact, contacttableFieldidentity, oldofferdata.offername, oldofferdata.rowId));
        }      

        return dataChangesResult;
    }

    // Datenangleich der Angebotsdaten
    performCheckOnPublished(publishedSince: string, offerdata: any): UserDataChanges {
        //Änderungsobjekt erstellen
        return new UserDataChanges(offerdata.principalDomain,
            userDataChangesdataCategorieIds.offerdata,
            this.stringService.DateNowToString(),
            "offers",
            userDataChangesState.pending,
            [new Field("Ausweisung seit", "publishedSince", offerdata.publishedSince, publishedSince)],
            null, offerdata.offername);

    }

    specialcaseReportLevelId(pendingchangesValues: any, principalDomain: string, offername: string, oldreportingLevelId: string, newreportingLevelId: string, pendingchangesreportingLevelId: string, fieldnameInTable: string, reportingLevelDesc: string) {
        if (oldreportingLevelId === newreportingLevelId) {
            // pending change refuse

            this.userChangeService.foundIfSingleUserChangeAlreadyExists(pendingchangesValues, userDataChangesdataCategorieIds.offerdata,
                "offers",
                principalDomain,
                offername,
                new Field(reportingLevelDesc, fieldnameInTable, oldreportingLevelId, newreportingLevelId)).subscribe((userDataChangesId) => {

                    if (userDataChangesId !== null && userDataChangesId !== undefined) {
                        this.userChangeService.refuseChangeByQuery(this.sqlQueryService.getProcessedChangeSqlQuery(userDataChangesId, userDataChangesState.refused)).subscribe(
                            (Response) => { console.log(Response) }, (error) => { console.log(error) }

                        );
                    }
                });





        }

    }
}