import { environment } from "src/environments/environment";
import { HttpClient } from "@angular/common/http";
import { TokenService } from './token.service';
import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

/**
 * Der Service spricht das API an, und liefert die Vermarkter-Daten
 */


@Injectable({
    providedIn: "root"
})
export class MarketerService {
    isConnected = false;
    private apiurl = environment.apiRootDirectory;

    private memberOfGroup = new Subject<string[]>();

    constructor(private http: HttpClient, private tokenService: TokenService) { }

    // liefert  die Lizenznehmers einer VG
    get memberOfMarketerGroup() {
        return this.memberOfGroup.asObservable();
    }


    // lies die Lizenznehmer eines Vermarkters VG
    readMemberOfMarkerters(principalDomain: string) {
        return this.http.post<any>(this.apiurl + "data/marketergroup/readMemberOfMarketerGroup.php", { "principalDomain": principalDomain });
    }

      // lies die Vermarkters VG
      readAllMarkerters() {
        return this.http.get<any>(this.apiurl + "data/marketergroup/readAllMarketers.php");
    }

    // lädt im Vermarkter-Kontext die Lizenznehmers einer VG
    public loadmemberOfGroup() {
        let user: any = JSON.parse(this.tokenService.getLoggedInUser());

        // Aufgrund der Service-Injection, muss dieses Property neu Initialisiert    
        let marketers: string[] = [];

        if (user !== null) {
            this.readMemberOfMarkerters(user.username).subscribe((response) => {
                
                if (response && response["data"])
                    for (let marketer of response["data"])
                        marketers.push(marketer);

                this.memberOfGroup.next(marketers.slice());
            }, (error) => { });
        }
    }


    /**
     *  lädt im Admin-Kontext die Lizenznehmers einer Vermarkter-Gesellschaft
     * @param principalDomain Mitgliedskürzel 
     */
    public loadmemberOfVGGroup(principalDomain: string) {
        let memberOfGroup = new Subject<string[]>()
        // Aufgrund der Service-Injection, muss dieses Property neu Initialisiert    
        let marketers: string[] = [];

        if (principalDomain !== null) {
            this.readMemberOfMarkerters(principalDomain).subscribe((response) => {
                
                if (response && response["data"])
                    for (let marketer of response["data"])
                        marketers.push(marketer);

                memberOfGroup.next(marketers.slice());
            }, (error) => { });
        }
        return memberOfGroup.asObservable();
    }

}
