import { HttpInterceptor, HttpRequest, HttpHandler } from "@angular/common/http";
import { TokenService } from "../services/token.service";
import { AuthService } from "../services/auth.service";
import { Injectable } from "@angular/core";

/**
 * Die Klasse setzt bei jedem Request das Token zur Identifizierung des Users im Backend
 */

@Injectable({
    providedIn: "root"
})
export class JwtInterceptor implements HttpInterceptor {

    /**
     * Konstruktor
     * @param tokenService, Service für Tokens
     * @param authService, Service für Auth 
     */
    constructor(private tokenService: TokenService, private authService: AuthService) { }

    /**
     * fängt jedes Request ab und setzt das Token zur Identifizierung des Users im Backend
     * @param request, das Request 
     * @param next, der nächste HttpHändler 
     */
    intercept(request: HttpRequest<any>, next: HttpHandler) {

        if (!this.tokenService.isJwtTokenValid())
            return next.handle(request);
            
        // das Token aus dem Storage lesen
        let jwtoken = this.tokenService.getLoggedInUser();

        // Wenn der user eingeloggt ist
        if (this.authService.isConnected && jwtoken !== null && jwtoken !== undefined && jwtoken.length > 0) {
            // Das Token als Json parsen
            let jwtokenParsed: any = JSON.parse(jwtoken);
            // Wenn Das geparste JSON ein jwt Feld verfügt
            if (jwtokenParsed !== null && jwtokenParsed !== undefined && jwtokenParsed.jwt) {
                const clonedRequest = request.clone({
                    setHeaders: {
                        "Authorization": jwtokenParsed.jwt,
                        "Access-Control-Allow-Origin": "*",
                        "Content-Type": "application/json",
                        "withCredentials": "true",                                              
                        "Access-Control-Allow-Methods": "GET,POST,PUT,DELETE",
                        "Access-Control-Allow-Headers": "Access-Control-Allow-Headers, cache-control, Access-Control-Max-Age, Access-Control-Allow-Origin, Content-Type, Access-Control-Allow-Methods, Authorization, X-Requested-With, Accept"
                    }
                });

                return next.handle(clonedRequest);
            }
        }
        return next.handle(request);


    }

}
