import { GenericDisplaySservice } from "src/app/services/genericDisplay.service";
import { Component, OnInit } from "@angular/core";


@Component({
  selector: "pagenotfound",
  templateUrl: "./pagenotfound.component.html",
  styleUrls: ["./pagenotfound.component.css"]
})
export class PagenotfoundComponent implements OnInit {

  constructor( private genericDisplaySservice: GenericDisplaySservice) { }

  ngOnInit() {

     // die Warnung anzeigen
     //this.genericDisplaySservice.showAlert("Seite nicht gefunden", "Oups: " + "...leider ist die erwünschte Seite nicht zu finden...", null, 1);

  }

}
