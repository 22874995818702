<br /><br /><br />
<div class="col-sm-10">
  <h4 align="center"><br /> Bitte melden Sie sich an<br /><br /></h4>
  <form [formGroup]="signInForm" (ngSubmit)="onSubmit()">
    <div class="form-group">
      <label for="email" class="required"> Benutzername</label>
      <input  autocomplete="off" type="text" id="username" class="form-control" formControlName="username">
    </div>
    <div class="form-group">

      <label for="password" class="required"> Passwort</label>
      <input type="password" id="password" class="form-control" formControlName="password">
    </div>
    <br /><br />
    <p align="center">
      <button class="btn btn-success" type="submit" [disabled]="signInForm.invalid" data-toggle="tooltip"
        data-placement="top" title="sich anmelden"><i class="fa fa-sign-in fa-2x" aria-hidden="true"></i></button>

    </p>
  </form>
  <p align="center" *ngIf="!authService.isConnected">
    <a class="nav-link" style="color:blue" (click)="popupAccountrecoveryComponent()"> <i class="fa fa-question-circle"
        aria-hidden="true"></i>
      &nbsp; Passwort vergessen</a>
  </p>
  <p class="text-danger">{{errorMessage}}</p>

</div>