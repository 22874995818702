import { Component, OnInit, Input } from '@angular/core';
import { TokenService } from '../services/token.service';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'sessioncountdown',
  templateUrl: './sessioncountdown.component.html',
  styleUrls: ['./sessioncountdown.component.css']
})

/**
 * Die Klasse steuert der countdown der Gültigkeit des Tokens
 */
export class SessioncountdownComponent implements OnInit {


  @Input() init: number = null;

  public counter: number = 0;
  constructor(private authService: AuthService, private tokenService: TokenService) { }

  ngOnInit() {
    this.startCountdown();

    setTimeout(() => {      
    }, this.tokenService.getRemainingTime() * 60000);

  }

  /**
   * startet der Countdown
   */
  startCountdown() {

    if (this.init && this.init >= 0) {
      this.counter = this.init;
      this.doCountdown();
    }
  }

  /**
   * dekrementiert die Minute
   */
  doCountdown() {
    setTimeout(() => {
      this.counter = this.counter - 1;
      this.processCountdown();
    }, 60000);
  }

  /**
   * prüft, ob die Zeit erreicht ist
   */
  processCountdown() {

    if (this.counter === 0) {
      // console.log("Your JWT has expired");
      // Ende Zeit
      this.authService.signOutUser();
    } else {
      
      this.doCountdown();
    }
  }
}
