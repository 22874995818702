import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit, Inject } from '@angular/core';

/**
 * Komponent-Klasse zum Anzeigen von Modal mit Warnungen, dass das Passwort erfolgreich geändert wurde
 */

@Component({
  selector: 'passwordchangeconfirmation',
  templateUrl: './passwordchangeconfirmation.component.html',
  styleUrls: ['./passwordchangeconfirmation.component.css']
})
export class PasswordchangeconfirmationComponent implements OnInit {

  title: string; 
  message: string;
  screen: string;
  constructor(public dialogRef: MatDialogRef<PasswordchangeconfirmationComponent>,  @Inject(MAT_DIALOG_DATA) private data: any) { }

  ngOnInit() {

    this.title = this.data.title;
    this.message = this.data.message;
    this.screen = this.data.screen;
  }

  cancelOption() {
    this.dialogRef.close();
  }
}
