import { environment } from 'src/environments/environment';
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

/**
 * Der Service spricht das API um die Locals-Daten an 
 */


@Injectable({
    providedIn: "root"
})
export class LocallistService {

    private apiurl = environment.apiRootDirectory;

    constructor(private _http: HttpClient) { }

    /**
     *  selektiert die Locals eines Angebotes
     * @param principalDomain Mitgliedskürzel
     * @param offername Angebotsname
     */
    selectData(principalDomain: string, offername: string) {
        if (principalDomain !== null && principalDomain !== undefined && offername !== null && offername !== undefined) {
            return this._http.post<any>(this.apiurl + "data/locallist/readByOffer.php", { "offername": offername, "principalDomain": principalDomain });
        }
    }

    /**
     * aktualisiert die locals, besonders bei Nameänderung eines Angebotes
     * @param oldOffername alter Angebotsname
     * @param newOffername neuer Angebotsname
     * @param principalDomain Mitgliedskürzel
     */
    updateLocal(oldOffername: string, newOffername: string, principalDomain: string) {
        return this._http.post<any>(this.apiurl + "data/locallist/update.php", {
            "oldoffername": oldOffername,
            "newoffername": newOffername,
            "principalDomain": principalDomain
        });

    }
}