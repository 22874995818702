import { AccountrecoveryComponent } from "../accountrecovery/accountrecovery.component";
import { ValidatorPatternService } from "src/app/services/validatorPattern.service";
import { GenericDisplaySservice } from "src/app/services/genericDisplay.service";
import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { CryptoService } from "src/app/services/crypto.service";
import { IMemberAccount } from "src/app/models/IMemberAccount";
import { TokenService } from "src/app/services/token.service";
import outputmessage from "src/assets/messagetokens.json";
import { AuthService } from "../../services/auth.service";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";


/**
 * Komponent-Klasse zum Einlogen
 */

@Component({
  selector: "signin",
  templateUrl: "./signin.component.html",
  styleUrls: ["./signin.component.css"]
})
export class SigninComponent implements OnInit {

  // Das Formular
  signInForm: UntypedFormGroup;
  // für den Signin Fehler
  errorMessage: string;
  //Declare the property
  @Output() connectedAccount: EventEmitter<IMemberAccount> = new EventEmitter<IMemberAccount>();


  /**
   * Konstruktor
   * @param formBuilder, Formbuilder-Objekt 
   * @param authService, Service für die Auth 
   * @param dialog, Matdialog 
   * @param router, Navigationshändler 
   * @param validatorPatternService, Service für Regex 
   * @param genericDisplaySservice, Service für die Warnungsanzeige 
   */
  constructor(private formBuilder: UntypedFormBuilder,
    public authService: AuthService, private dialog: MatDialog,
    private router: Router,
    private validatorPatternService: ValidatorPatternService,
    private genericDisplaySservice: GenericDisplaySservice,
    private cryptoService: CryptoService, private tokenService: TokenService) {
  }

  ngOnInit() {
    /* Initilialiserung des Forms */

    this.signInForm = this.formBuilder.group({
      username: ["", [Validators.required,
      Validators.pattern(this.validatorPatternService.regexOfUsername)]],

      password: ["", [Validators.required,
      Validators.pattern(this.validatorPatternService.regexOfPasswordChars),
      Validators.minLength(this.validatorPatternService.getPasswordMinLength),
      Validators.maxLength(this.validatorPatternService.getPasswordMaxLength)]]
    });

    // Falls User bereits eingeloggt ist, bitte die Info aus dem Storage ziehen 
    let user: any = JSON.parse(this.tokenService.getLoggedInUser());
    if (user !== null) {
      // Falls User bereits eingeloggt ist, bitte  die Info aus dem Storage ziehen und dem Parent-Control übergeben
      // sendet der Kontotyp zum Parent-Control
      this.connectedAccount.emit({
        isAdmin: user.isAdmin,
        isMarketer: user.isMarketer,
        isMember: user.accounttype === "Mitglied",
        showOrdercenter: user.username === "mngomsi"
        
      });
    }
  }


  onSubmit() {

    const username = this.signInForm.get("username").value;
    const password = this.signInForm.get("password").value;

    this.authService.signInUser(username, password).subscribe((response) => {

      //Zugriff erlaubt
      this.authService.isConnected = response !== null && response !== undefined && response.jwt;
      //Passwort-Textfeld leeren um neu Angabe anzufordern, wenn der Benutzer  nach Ende der Tokenssitzung automatisch ausgeloggt wird
      this.signInForm.get("password").reset();

      this.authService.isAdminUser.subscribe((isadminResponse) => {
        // Default nach dem Login Ansicht auswählen
        if (isadminResponse) {
          this.router.navigateByUrl("datachangeprocessing");
        }
        else {
          this.router.navigateByUrl("customerdata");
        }

        let konto: IMemberAccount = {
          isAdmin: this.cryptoService.decryptData(response.isAdmin),
          isMarketer: this.cryptoService.decryptData(response.isMarketer),
          isMember: this.cryptoService.decryptData(response.accounttype) === "Mitglied",
          showOrdercenter: this.cryptoService.decryptData(response.username)  === "mngomsi"
          
        }

        // sendet der Kontotyp zum Parent-Control
        this.connectedAccount.emit(konto);


      }, (isadminError) => {


        this.errorMessage = isadminError;
        //Falls das Objekt Error ein Message-Attribut hat, genau das zurückliefern
        if (isadminError.message) {
          this.errorMessage = isadminError.message;
        }
        // die Warnung anzeigen
        this.genericDisplaySservice.showAlert("Warnung", this.errorMessage);
      });

    },
      (errorResponse) => {        

        //Zugriff verweigert
        //Falls der User unbekannt ist
        if (outputmessage && outputmessage.unknownUser) {

          // die Warnung anzeigen
          this.genericDisplaySservice.showAlert(errorResponse, outputmessage.unknownUser.replace("[[username]]", username));

        }
      }
    );


  }

  /**
   * handelt die Passwort-vergessen-Funktion
   */
  popupAccountrecoveryComponent() {

    this.dialog.open(AccountrecoveryComponent, {
      height: "380px",
      width: "500px",

    });
  }
}
