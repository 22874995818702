import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-membernewsdisplay',
  templateUrl: './membernewsdisplay.component.html',
  styleUrls: ['./membernewsdisplay.component.css']
})
export class MembernewsdisplayComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<MembernewsdisplayComponent>,  @Inject(MAT_DIALOG_DATA) public data: any) { }
  
  ngOnInit(): void {
  
  }
  cancelOption() {
    this.dialogRef.close();
  }
  extractNews(){
    return this.data.news;
  }
}
