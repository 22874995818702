<!-- Komponent derzeit NICHT verwendet. Steht hier für eine Simulation der IOM Buchung bei der ÖWA -->
<div class="check-box-container d-inline-block">
    <div class="round">
      <input
        type="checkbox" [checked]="isChecked"
        id="{{ offerid }}"
        (change)="onChangeHandler($event)"
        (click)="addOrRemove($event)"
      />
      <label
        for="{{ offerid }}"
        [ngStyle]="{ background: isChecked ? color : '#fff', 'border-color': color }"
      ></label>
    </div>
    <span class="label">{{ websitename }}</span>
  </div>
