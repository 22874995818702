import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject } from 'rxjs';
import { ChangesFieldGroupedByPrincipalAndOffer } from 'src/app/models/ChangesFieldGroupedList.model';
import { AuthService } from 'src/app/services/auth.service';
import { DataCategoriesService } from 'src/app/services/dataCategories.service';
import { DatastoreService } from 'src/app/services/dataStore.service';
import { MemberService } from 'src/app/services/member.service';
import { OfferService } from 'src/app/services/offer.service';
import { TokenService } from 'src/app/services/token.service';
import { UserDataChangesService } from 'src/app/services/userDataChanges.service';

@UntilDestroy()
@Component({
  selector: 'app-memberprofil',
  templateUrl: './memberprofil.component.html',
  styleUrls: ['./memberprofil.component.css']
})
export class MemberprofilComponent implements OnInit {

  user: any = {};
  member: any = {};
  reports: any[] = [];

  /**
   * Konstruktor
   * @param tokenService Token-Service
   * @param dataStoreService localstorage Leser
   * @param memberService Service zum Lesen der Mitgliederinformationen
   * @param authService  Service zur Authentifizierung
   * @param offerService Angebot-Service
   */
  constructor(private userDataChangesService: UserDataChangesService, private dataCatgeories: DataCategoriesService, private tokenService: TokenService, private dataStoreService: DatastoreService, private memberService: MemberService, private authService: AuthService, private offerService: OfferService) { }

    // einzige Spalte der Mattable
    public displayedColumns = ["principalDomain"];
    // Datenquelle der Mattable
    public dataSource = new MatTableDataSource<ChangesFieldGroupedByPrincipalAndOffer>();
    // Daten-Kategorie temporäre speichern
    private dataCategoriesTemp = new BehaviorSubject<any>(null);
  
    // Custom-Property
    @ViewChild(MatSort, { static: true }) sort: MatSort;
    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
   
  
  ngOnInit(): void {

    let selectedMember = this.dataStoreService.getDataFromLocalstorage("principalDomainSelected");
    // Aufruf des Services zur Prüfung ob das Token noch valide ist
    this.authService.isUserTokenStillValid().pipe(untilDestroyed(this)).subscribe(isTokenValid => {
      // Falls das Token noch valid ist
      if (isTokenValid) {

        this.user = JSON.parse(this.tokenService.getLoggedInUser());

        this.memberService.readMember(selectedMember).subscribe((response) => {
          // true/false auf JA/NEIN umwandeln
          for (const [key, value] of Object.entries(response)) {
            if (response[key] === true)
              response[key] = "JA";
            else if (response[key] === false)
              response[key] = "NEIN";
          }          

          this.member = response
          // unique items sicherstellen
          this.member.mobiletypes.data = Array.from(new Set(this.member.mobiletypes.data)).join(', ');

        });



         // Kundenänderungen des Members laden
         this.userDataChangesService.selectDataChanges(selectedMember).subscribe((response) => {

          if (response && response["data"] != null && response["data"].length > 0) {
            // Datasource der Mattable  setzen
            this.dataSource.data = this.userDataChangesService.groupChangedFieldObjectToList(response["data"]);

            // nötig für Tooltip auf Kunden
            this.dataCatgeories.readDataCategories().subscribe((catResponse) => {

              this.dataCategoriesTemp.next(JSON.stringify(catResponse));
            });

          }

        });




        // Service-Aufruf der Angebote eines Users
        this.offerService.readOffers(selectedMember).pipe(untilDestroyed(this)).subscribe((response) => {

          // Falls das Anwort Daten enthält 
          if (response && response["data"] !== undefined && response["data"] !== null && response["data"].length > 0) {
            // Daten durchgehen
            for (let offer of response["data"]) {
              // Falls das Angebot ein berciht gebucht hat.  1 heisst keine Reports
              if (offer.reportingLevelId !== "1")
                this.reports.push(offer);
            }
          }
        });
      }

    });

  }

  /**
   * css Style festlegen
   * @param value 
   * @returns 
   */
  getStyle(value: string) {

    if (value === "JA")
      return "jaStyle";

    return "neinStyle";
  }

}
